import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { BrowserRouter } from "react-router-dom";
import "./i18n";
import { AppProvider } from "./useAppProvider";
import {
  IsBoolean,
  IsEmail,
  IsNotEmpty,
  IsString,
  IsUUID,
  Matches,
  MaxLength,
  MinLength,
  ValidateIf,
} from "class-validator";
import { Match } from "./match.decorator";

export interface User {
  email: string;
  firstname: string;
  lastname: string;
  role: "supervisor" | "user";
  supervisor?: string;
  uuid: string;
}

export interface LoginResponse extends User {
  token: string;
}

export class ApiOrderItemEntity {
  uuid: string;
  order: ApiOrderEntity;
  No: string;
  Description: string;
  Description2: string;
  AMA_VendorName: string;
  Base_Unit_of_Measure: string;
  GTIN: string;
  Gross_Weight: number;
  Item_Category_Code: string;
  Net_Weight: number;
  Search_Description: string;
  Vendor_Item_No: string;
  Vendor_No: string;
  Unit_Price: number;
  quantity: number;
  Last_Direct_Cost: number;
  created: string;
  updated: string;
  deleted: string | null;
}

export class PasswordForm {
  @IsUUID("4", { message: "UUID wrong" })
  uuid: string;

  @IsNotEmpty({ message: "Password is required" })
  @MinLength(8, { message: "Password must be at least 8 characters long" })
  @MaxLength(50, { message: "Password must be at most 50 characters long" })
  @Matches(/((?=.*\d)|(?=.*\W+))(?![.\n])(?=.*[A-Z])(?=.*[a-z]).*$/, {
    message:
      "Password must contain at least one uppercase letter, one lowercase letter and one number or special character",
  })
  password: string;

  @IsNotEmpty({ message: "Password confirmation is required" })
  @MinLength(8, {
    message: "Password confirmation must be at least 8 characters long",
  })
  @MaxLength(50, {
    message: "Password confirmation must be at most 50 characters long",
  })
  @Match("password", { message: "Passwords do not match" })
  passwordConfirmation: string;
}

export class ApiOrderEntity {
  uuid: string;
  user: ApiUserEntity;
  items: ApiOrderItemEntity[];
  accepted: boolean;
  created: string;
  updated: string;
  deleted: string | null;
  orderNumber: string | null;
  accept_required: boolean;
  accepted_by: ApiUserEntity | null;
  accepted_at: string | null;
  rejected: boolean;
  rejected_by: ApiUserEntity | null;
  rejected_at: string | null;
}

export class ApiUserEntity {
  @ValidateIf((o) => o.uuid !== undefined && o.uuid !== null)
  @IsUUID("4", { message: "Invalid UUID" })
  uuid?: string;

  @ValidateIf((o) => o.active !== undefined && o.active !== null)
  @IsBoolean({ message: "Invalid boolean" })
  active: boolean;

  rejected_orders?: ApiOrderEntity[];
  accepted_orders?: ApiOrderEntity[];
  deletedOrders?: ApiOrderEntity[];
  orders?: ApiOrderEntity[];

  @IsBoolean({ message: "Invalid boolean" })
  @IsBoolean({ message: "Invalid boolean" })
  supervisor: boolean;

  @IsString({ message: "Invalid string" })
  debitor: string;

  @IsEmail({}, { message: "Invalid email" })
  email: string;

  @IsString({ message: "Invalid string" })
  @MinLength(1, { message: "Invalid string" })
  firstname: string;

  @IsString({ message: "Invalid string" })
  @MinLength(1, { message: "Invalid string" })
  lastname: string;

  @ValidateIf(
    (o) => o.supervisorUuid !== undefined && o.supervisorUuid !== null
  )
  @IsUUID("4", { message: "Invalid UUID" })
  supervisorUuid: string | null;

  @ValidateIf(
    (o) => o.emailNotifications !== undefined && o.emailNotifications !== null
  )
  @IsBoolean({ message: "Invalid boolean" })
  emailNotifications: boolean;

  created?: string;
  updated?: string;
  deleted: string | null;
}

export class CreateApiUserEntity extends ApiUserEntity {
  @IsNotEmpty({ message: "Password is required" })
  @MinLength(8, { message: "Password must be at least 8 characters long" })
  @MaxLength(50, { message: "Password must be at most 50 characters long" })
  @Matches(/((?=.*\d)|(?=.*\W+))(?![.\n])(?=.*[A-Z])(?=.*[a-z]).*$/, {
    message:
      "Password must contain at least one uppercase letter, one lowercase letter and one number or special character",
  })
  password: string;

  @IsNotEmpty({ message: "Password confirmation is required" })
  @MinLength(8, {
    message: "Password confirmation must be at least 8 characters long",
  })
  @MaxLength(50, {
    message: "Password confirmation must be at most 50 characters long",
  })
  @Match("password", { message: "Passwords do not match" })
  passwordConfirmation: string;
}

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(
  <React.StrictMode>
    <BrowserRouter>
      <AppProvider>
        <App />
      </AppProvider>
    </BrowserRouter>
  </React.StrictMode>
);

reportWebVitals();
