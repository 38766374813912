import {
  FC,
  useState,
  useEffect,
  ReactNode,
  useMemo,
  ChangeEvent,
} from "react";
import { Link, useParams, useSearchParams } from "react-router-dom";
import api from "../../api";
import { useForm } from "react-hook-form";
import Input from "../../components/input";
import Checkbox from "../../components/checkbox";
import { UserIcon } from "@heroicons/react/24/solid";
import dayjs from "dayjs";
import de from "dayjs/locale/de";
import utc from "dayjs/plugin/utc";
import { formatEuro } from "../../currency";
import { calculateSum } from "../../useAppProvider";
import { ApiOrderEntity, ApiUserEntity, PasswordForm } from "../../index";
import OrderItem from "../../components/orderitem";
import { sleep } from "../../sleep";
import CircularProgress from "../../components/circularprogress";
import { classValidatorResolver } from "@hookform/resolvers/class-validator";
import { useTranslation } from "react-i18next";

dayjs.locale(de);
dayjs.extend(utc);

type TabProps = {
  text: string;
  tab: string;
};

const getOrderStatus = (item: ApiOrderEntity): string => {
  if (item.accept_required) {
    if (item.accepted) {
      return "Bestätigt";
    }
    if (item.rejected) {
      return "Abgelehnt";
    }
    return "Warten auf Bestätigung";
  }
  return "Bestätigt";
};

const Tab: FC<TabProps> = (props) => {
  const [searchParams, setSearchParams] = useSearchParams();

  const active = useMemo(() => {
    const tab = searchParams.get("tab");
    return tab === props.tab;
  }, [props.tab, searchParams]);

  const handleClick = () => {
    setSearchParams({ tab: props.tab });
  };

  return (
    <button
      type={"button"}
      onClick={handleClick}
      className={"px-4 relative py-3"}
    >
      {props.text}
      {active && (
        <div
          className={
            "absolute bottom-0 left-0 w-full h-0 border-b-1 border-solid border-orange-500"
          }
        />
      )}
    </button>
  );
};

type TabsProps = {
  children?: ReactNode;
};

const Tabs: FC<TabsProps> = (props) => {
  return (
    <div
      className={
        "flex flex-row flex-nowrap mt-3 border-b-1 border-solig border-gray-300"
      }
    >
      {props.children}
    </div>
  );
};

type TabItemProps = {
  tab: string;
  children?: ReactNode;
};

const TabItem: FC<TabItemProps> = (props) => {
  const [searchParams] = useSearchParams();
  const active = useMemo(() => {
    const tab = searchParams.get("tab");
    return tab === props.tab;
  }, [props.tab, searchParams]);

  return <div className={active ? "mt-8" : "hidden"}>{props.children}</div>;
};

const User: FC = () => {
  const { uuid } = useParams();
  const { t } = useTranslation();
  const [loading, setLoading] = useState<boolean>(false);
  const form = useForm<ApiUserEntity>({
    mode: "onChange",
    resolver: classValidatorResolver(ApiUserEntity),
  });
  const passwordForm = useForm<PasswordForm>({
    mode: "onChange",
    resolver: classValidatorResolver(PasswordForm),
  });
  const [orders, setOrders] = useState<ApiOrderEntity[]>([]);
  const [search, setSearch] = useState<string>("");
  const [searchParams, setSearchParams] = useSearchParams();
  const [month, setMonth] = useState<string>("");

  useEffect(() => {
    let cancel = false;

    const active_uuid =
      form.getValues("uuid") || passwordForm.getValues("uuid");

    if (uuid && !active_uuid) {
      setLoading(true);
      Promise.all([
        api.get<ApiUserEntity>(`/api-nonfood/my-users/${uuid}`),
        api.get<ApiOrderEntity[]>(`/api-nonfood/my-users/${uuid}/orders`),
        sleep(800),
      ])
        .then((res) => {
          if (!cancel) {
            form.reset(res[0].data);
            passwordForm.setValue("uuid", res[0].data.uuid || "");
            setOrders(res[1].data);
          }
        })
        .catch((e) => console.log(e))
        .finally(() => {
          if (!cancel) {
            setLoading(false);
          }
        });
    }

    return () => {
      cancel = true;
    };
  }, [form, passwordForm, uuid]);

  const submit = (data: ApiUserEntity) => {
    setLoading(true);
    Promise.all([
      api.put<ApiUserEntity>(`/api-nonfood/my-users/${uuid}`, data),
      sleep(800),
    ])
      .then((res) => {
        form.reset(res[0].data);
      })
      .catch((e) => console.log(e))
      .finally(() => setLoading(false));
  };

  const months = useMemo(() => {
    const months = new Map<string, ApiOrderEntity[]>();
    orders.forEach((order) => {
      const month = dayjs.utc(order.created).format("YYYY-MM-01");
      if (months.has(month)) {
        months.get(month)?.push(order);
      } else {
        months.set(month, [order]);
      }
    });
    return months;
  }, [orders]);

  const submitPassword = (data: PasswordForm) => {
    setLoading(true);
    Promise.all([
      api.post<void>(`/api-nonfood/my-users/${uuid}/password`, data),
      sleep(800),
    ])
      .catch((e) => console.log(e))
      .finally(() => setLoading(false));
  };

  const handleSearchChange = (e: ChangeEvent<HTMLInputElement>) => {
    setSearch(e.target.value);
    const tab = searchParams.get("tab");
    if (tab !== "orders") {
      setSearchParams({ tab: "orders" });
    }
  };

  const handleMonthChange = (e: ChangeEvent<HTMLSelectElement>) => {
    setMonth(e.target.value);
  };

  const acceptOrder = (order: ApiOrderEntity) => {
    setLoading(true);
    Promise.all([
      api.post<ApiOrderEntity>(
        "/api-nonfood/orders/" + order.uuid + "/accept",
        order
      ),
      sleep(800),
    ])
      .then((res) => {
        setOrders((orders) => {
          const index = orders.findIndex((o) => o.uuid === order.uuid);
          if (index >= 0) {
            orders[index] = res[0].data;
          }
          return [...orders];
        });
      })
      .catch((e) => console.log(e))
      .finally(() => setLoading(false));
  };

  const rejectOrder = (order: ApiOrderEntity) => {
    setLoading(true);
    Promise.all([
      api.post<ApiOrderEntity>("/api-nonfood/orders/" + order.uuid + "/reject"),
      sleep(800),
    ])
      .then((res) => {
        setOrders((orders) => {
          const index = orders.findIndex((o) => o.uuid === order.uuid);
          if (index >= 0) {
            orders[index] = res[0].data;
          }
          return [...orders];
        });
      })
      .catch((e) => console.log(e))
      .finally(() => setLoading(false));
  };

  const handleQuantityChange = (quantity: number, uuid: string) => {
    const copy = [...orders];
    for (let i = 0; i < copy.length; i++) {
      for (let x = 0; x < copy[i].items.length; x++) {
        if (copy[i].items[x].uuid === uuid) {
          copy[i].items[x].quantity = quantity;
          break;
        }
      }
    }
    setOrders(copy);
  };

  const handleArtikelRemove = (uuid: string) => {
    const copy = [...orders];
    for (let i = 0; i < copy.length; i++) {
      for (let x = 0; x < copy[i].items.length; x++) {
        if (copy[i].items[x].uuid === uuid) {
          copy[i].items.splice(x, 1);
          break;
        }
      }
    }
    setOrders(copy);
  };

  return (
    <div className={"container max-w-screen-lg mx-auto py-8"}>
      <div className={"flex items-center gap-1 pb-2"}>
        <Link
          className={"text-blue-500 text-sm hover:underline"}
          to={"/account"}
        >
          {t("myAccount")}
        </Link>
        <span>•</span>
        <Link
          className={"text-blue-500 text-sm hover:underline"}
          to={"/account/users"}
        >
          {t("users.title")}
        </Link>
        <span>•</span>
        <span className={"text-gray-500 text-sm"}>
          {form.watch("firstname")} {form.watch("lastname")}
        </span>
      </div>
      {loading && (
        <div
          className={
            "w-full h-[400px] flex flex-row justify-center items-center"
          }
        >
          <CircularProgress />
        </div>
      )}
      {form.watch("uuid") && !loading && (
        <div className={"flex flex-col flex-nowrap"}>
          <div className={"flex flex-row flex-nowrap gap-4 items-center"}>
            <div
              className={
                "bg-orange-400 w-16 h-16 rounded-full flex flex-row justify-center items-center"
              }
            >
              <UserIcon className={"w-10 h-10 text-white"} />
            </div>
            <h1 className={"text-xl"}>
              {form.watch("firstname")} {form.watch("lastname")}
            </h1>
            <div className={"grow"} />
            <div>
              <input
                type={"search"}
                placeholder={"Bestellungen durchsuchen"}
                onChange={handleSearchChange}
                value={search}
                className={
                  "py-2 px-3 text-sm rounded min-w-[300px] transition-all focus:min-w-[400px] outline-none"
                }
              />
            </div>
          </div>
          <Tabs>
            <Tab tab={"personal"} text={"Personal"} />
            <Tab tab={"password"} text={"Passwort ändern"} />
            <Tab tab={"orders"} text={"Bestellungen"} />
          </Tabs>
          <TabItem tab={"personal"}>
            <form onSubmit={form.handleSubmit(submit)}>
              <div className={"flex flex-col gap-4 flex-nowrap"}>
                <Input
                  {...form.register("firstname")}
                  label={"Vorname"}
                  errors={form.formState.errors}
                />
                <Input
                  {...form.register("lastname")}
                  label={"Nachname"}
                  errors={form.formState.errors}
                />
                <Input
                  {...form.register("email")}
                  type={"email"}
                  label={"E-Mail"}
                  errors={form.formState.errors}
                />
                <Checkbox
                  onChange={(e) => form.setValue("emailNotifications", e)}
                  checked={form.watch("emailNotifications")}
                  label={"E-Mail Benachrichtigungen senden?"}
                  errors={form.formState.errors}
                  name={"emailNotifications"}
                />
                <Checkbox
                  onChange={(e) => form.setValue("active", e)}
                  checked={form.watch("active")}
                  label={"Login aktiv?"}
                  errors={form.formState.errors}
                  name={"active"}
                />
                <div className={"flex flex-row"}>
                  <button
                    type={"submit"}
                    className={"text-white bg-orange-400 rounded px-4 py-2"}
                  >
                    Speichern
                  </button>
                </div>
              </div>
            </form>
          </TabItem>
          <TabItem tab={"password"}>
            <form onSubmit={passwordForm.handleSubmit(submitPassword)}>
              <div className={"flex flex-col flex-nowrap gap-4"}>
                <Input
                  type={"password"}
                  {...passwordForm.register("password")}
                  label={"Neues Passwort"}
                  placeholder={"Neues Passwort"}
                  errors={passwordForm.formState.errors}
                />
                <Input
                  type={"password"}
                  {...passwordForm.register("passwordConfirmation")}
                  label={"Neues Passwort wiederholen"}
                  placeholder={"Neues Passwort wiederholen"}
                  errors={passwordForm.formState.errors}
                />
                <div className={"flex flex-row"}>
                  <button
                    type={"submit"}
                    className={"text-white bg-orange-400 rounded px-4 py-2"}
                  >
                    Speichern
                  </button>
                </div>
              </div>
            </form>
          </TabItem>
          <TabItem tab={"orders"}>
            {months.size === 0 && <div>Keine Bestellungen vorhanden</div>}
            {months.size > 0 && (
              <div className={"flex flex-col gap-4"}>
                <div>
                  <select
                    className={
                      "py-2 px-3 text-sm rounded min-w-[300px] outline-none"
                    }
                    value={month}
                    onChange={handleMonthChange}
                  >
                    <option value={""}>Monat wählen</option>
                    {Array.from(months.keys()).map((month) => (
                      <option key={month} value={month}>
                        {dayjs.utc(month).format("MMMM YYYY")}
                      </option>
                    ))}
                  </select>
                </div>
                {Array.from(months.entries())
                  .filter(([m]) => {
                    if (month.length === 0) {
                      return true;
                    }
                    return m === month;
                  })
                  .filter(([_, orders]) => {
                    if (!search.length) {
                      return true;
                    }
                    const searchLower = search.toLowerCase();
                    return orders.some((order) => {
                      return order.items.some((orderItem) => {
                        const description = orderItem.Description.toLowerCase();
                        const no = orderItem.Vendor_Item_No.toLowerCase();
                        const vendor = orderItem.AMA_VendorName.toLowerCase();

                        return (
                          description.includes(searchLower) ||
                          no.includes(searchLower) ||
                          vendor.includes(searchLower)
                        );
                      });
                    });
                  })
                  .map(([month, orders]) => (
                    <div key={month} className={"flex flex-row flex-nowrap"}>
                      <div className={"min-w-[200px]"}>
                        <h2 className={"text-xl"}>
                          {dayjs.utc(month).format("MMMM YYYY")}
                        </h2>
                      </div>
                      <div className={"flex flex-col gap-4 grow rounded"}>
                        {orders.map((order) => (
                          <div
                            key={order.uuid}
                            className={"rounded-md border-1 border-solid"}
                          >
                            <div
                              className={
                                "rounded-t-md p-3  bg-gray-200 flex flex-row flex-nowrap gap-8 sticky top-0 left-0"
                              }
                            >
                              <div>
                                <p
                                  className={"font-bold text-xs text-gray-600"}
                                >
                                  Bestellung aufgegeben
                                </p>
                                <p className={"text-xs"}>
                                  {dayjs(order.created).format("DD.MM.YYYY")}
                                </p>
                              </div>
                              <div>
                                <p
                                  className={"font-bold text-xs text-gray-600"}
                                >
                                  Summe
                                </p>
                                <p className={"text-xs"}>
                                  {formatEuro(calculateSum(order.items))}
                                </p>
                              </div>
                              <div className={"grow"} />
                              {order.accept_required &&
                                !order.accepted &&
                                !order.rejected && (
                                  <div
                                    className={
                                      "flex flex-row flex-nowrap justify-center items-center gap-2"
                                    }
                                  >
                                    <button
                                      className={
                                        "text-sm text-blue-500 hover:underline"
                                      }
                                      type={"button"}
                                      onClick={() => acceptOrder(order)}
                                    >
                                      Bestätigen
                                    </button>
                                    <button
                                      className={
                                        "text-sm text-red-400 hover:underline"
                                      }
                                      type={"button"}
                                      onClick={() => rejectOrder(order)}
                                    >
                                      Ablehnen
                                    </button>
                                  </div>
                                )}
                              <div className={"flex flex-col flex-nowrap"}>
                                <p
                                  className={"font-bold text-xs text-gray-600"}
                                >
                                  Status
                                </p>
                                <p className={"text-xs"}>
                                  {getOrderStatus(order)}
                                </p>
                              </div>
                            </div>
                            <div className={"p-4 flex flex-col gap-4"}>
                              {order.items.map((item, index) => (
                                <OrderItem
                                  edit={
                                    order.accept_required &&
                                    !order.accepted &&
                                    !order.rejected
                                  }
                                  handleRemove={handleArtikelRemove}
                                  onChange={handleQuantityChange}
                                  key={index}
                                  artikel={item}
                                />
                              ))}
                            </div>
                          </div>
                        ))}
                      </div>
                    </div>
                  ))}
              </div>
            )}
          </TabItem>
        </div>
      )}
    </div>
  );
};

export default User;
