import { Artikel, artikelDescription, useApp } from "../useAppProvider";
import { FC, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import api from "../api";
import { PhotoIcon } from "@heroicons/react/24/solid";
import { formatEuro } from "../currency";
import { useTranslation } from "react-i18next";

interface ArtikelProps {
  artikel: Artikel;
}

const ArtikelComponent: FC<ArtikelProps> = (props) => {
  const app = useApp();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [img, setImage] = useState<string | null>(null);
  const [loading, setLoading] = useState<boolean>(false);

  useEffect(() => {
    let cancel = false;

    setLoading(true);
    api
      .get<string | void>("/api-nonfood/artikel/" + props.artikel.No + "/image")
      .then((res) => {
        if (!cancel && res.data) {
          setImage(res.data);
        }
      })
      .finally(() => {
        if (!cancel) {
          setLoading(false);
        }
      });

    return () => {
      cancel = true;
    };
  }, [props.artikel.No]);

  return (
    <div
      className={"bg-white rounded-md max-w-64 w-64 cursor-pointer flex-grow"}
      onClick={() => navigate("/" + props.artikel.No)}
    >
      <div
        className={
          "w-full h-64 flex flex-row justify-center items-center transition rounded-t-md bg-no-repeat bg-center bg-contain" +
          (loading ? " bg-gray-100 animate-pulse" : " bg-white")
        }
        style={{ backgroundImage: "url(" + img + ")" }}
      >
        {img === null && <PhotoIcon className={"w-6 h-6"} />}
      </div>
      <div className={"w-full py-2"}>
        <h5 className={"text-xs px-2 truncate"}>
          {t(
            "categories." +
              app.getCategoryCodeByName(props.artikel.category) +
              ".title"
          )}
        </h5>
        <h4 className={"font-bold text-sm px-2 truncate"}>
          {artikelDescription(props.artikel)}
        </h4>
        <p className={"text-xs text-gray-500 px-2 truncate"}>
          {props.artikel.No}
        </p>
        {!app.validating && app.loggedIn && (
          <p className={"text-xs px-2 mt-1"}>
            {formatEuro(props.artikel.Unit_Price)}
          </p>
        )}
      </div>
    </div>
  );
};

export default ArtikelComponent;
