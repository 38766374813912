import { FC } from "react";
import { HomeIcon, UserIcon } from "@heroicons/react/24/solid";
import { Link, useLocation } from "react-router-dom";
import { ShoppingCartIcon } from "@heroicons/react/24/outline";
import i18n from "../i18n";
import france from "../assets/icons8-france-24.png";
import german from "../assets/icons8-germany-24.png";
import { useTranslation } from "react-i18next";
import { useApp } from "../useAppProvider";

const Header: FC = () => {
  const app = useApp();
  const location = useLocation();
  const { t } = useTranslation();
  const isRoute = (route: string) => location.pathname === route;
  const isDynamicRoute = (route: string) =>
    decodeURIComponent(location.pathname).startsWith(route);

  return (
    <header className={"w-full bg-gray-800"}>
      <div
        className={
          "container mx-auto py-3 flex flex-row text-white items-center justify-center gap-2"
        }
      >
        <Link
          to={"/"}
          className={
            "py-2 px-3 rounded-lg flex flex-row flex-nowrap justify-center items-center text-sm gap-2" +
            (isRoute("/") ? " text-orange-400" : " text-white")
          }
        >
          <HomeIcon className="w-4 h-4" />
          <span>{t("homepage")}</span>
        </Link>
        <div className={"grow"} />
        {app.loggedIn && (
          <Link
            to={"/account"}
            className={
              "py-2 px-3 rounded-lg flex flex-row flex-nowrap justify-center items-center text-sm gap-2" +
              (isDynamicRoute("/account") ? " text-orange-400" : " text-white")
            }
          >
            <UserIcon className="w-4 h-4" />
            <span>{t("profile")}</span>
          </Link>
        )}
        <div className={"flex flex-row flex-nowrap"}>
          <button
            onClick={() => app.setCartModal(true)}
            className={
              "relative py-2 px-3 flex flex-row flex-nowrap justify-center items-center text-sm gap-2"
            }
          >
            <ShoppingCartIcon className={"w-4 h-4"} />
            <span>{t("cart")}</span>
            {app.cart.length > 0 && (
              <div
                className={
                  "bg-orange-500 w-3 h-3 absolute top-0 right-0 rounded-full"
                }
              />
            )}
          </button>
          <button
            className={"ml-6 w-[40px] min-w-[40px]"}
            onClick={() => i18n.changeLanguage("fr-FR")}
          >
            <img
              className={
                "hover:scale-105 transition-all duration-200 rounded-full" +
                (i18n.language === "fr-FR" ? " shadow-amber-100 shadow-md" : "")
              }
              src={france}
              alt={"france"}
            />
          </button>
          <button
            className={"w-[40px] min-w-[40px]"}
            onClick={() => i18n.changeLanguage("de-DE")}
          >
            <img
              className={
                "hover:scale-105 transition-all duration-200 rounded-full" +
                (i18n.language === "de-DE" ? " shadow-amber-100 shadow-md" : "")
              }
              src={german}
              alt={"german"}
            />
          </button>
        </div>
        {!app.loggedIn && (
          <Link
            to={"/login"}
            className={"py-2 px-4 text-xs bg-orange-400 rounded-md text-white"}
          >
            {t("login")}
          </Link>
        )}
        {app.loggedIn && (
          <button
            onClick={() => app.setLogoutModal(true)}
            className="py-2 px-4 text-xs bg-orange-400 rounded-md text-white"
          >
            {t("logout")}
          </button>
        )}
      </div>
    </header>
  );
};

export default Header;
