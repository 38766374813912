import { ChangeEvent, FC, useMemo, useState } from "react";
import {
  getShopCategoryItems,
  Artikel,
  useApp,
  artikelDescription,
} from "../useAppProvider";
import { useTranslation } from "react-i18next";
import { ChevronDownIcon, MinusIcon } from "@heroicons/react/24/solid";
import { AnimatePresence, motion } from "framer-motion";
import ArtikelComponent from "../components/artikel";
import FilterButton from "../components/filter-button";

const Home: FC = () => {
  const app = useApp();
  const { t } = useTranslation();
  const [showItems, setShowItems] = useState<number>(20);
  const [search, setSearch] = useState<string>("");
  const [sortPrice, setSortPrice] = useState<"ASC" | "DESC" | null>(null);

  const items = useMemo(() => {
    const artikel: Artikel[] = [];

    if (app.selectedCategories.first !== null) {
      if (app.selectedCategories.second !== null) {
        if (app.selectedCategories.third !== null) {
          artikel.push(...getShopCategoryItems(app.selectedCategories.third));
        } else {
          artikel.push(...getShopCategoryItems(app.selectedCategories.second));
        }
      } else {
        artikel.push(...getShopCategoryItems(app.selectedCategories.first));
      }
    } else {
      for (let i = 0; i < app.categories.length; i++) {
        artikel.push(...getShopCategoryItems(app.categories[i]));
      }
    }

    const searchLower = search.toLowerCase();

    return artikel
      .filter((item) => {
        if (!search.length) {
          return true;
        }

        const description = artikelDescription(item).toLowerCase();
        const no = item.No.toLowerCase();
        const vendorno = item.Vendor_No.toLowerCase();
        const category = item.category?.toLowerCase() || "";
        const searchDescription = item.Search_Description.toLowerCase();

        return (
          description.includes(searchLower) ||
          no.includes(searchLower) ||
          vendorno.includes(searchLower) ||
          category.includes(searchLower) ||
          searchDescription.includes(searchLower)
        );
      })
      .sort((a, b) => {
        if (sortPrice === null) {
          return 0;
        }
        if (sortPrice === "ASC") {
          return a.Last_Direct_Cost - b.Last_Direct_Cost;
        }
        return b.Last_Direct_Cost - a.Last_Direct_Cost;
      });
  }, [
    app.categories,
    app.selectedCategories.first,
    app.selectedCategories.second,
    app.selectedCategories.third,
    search,
    sortPrice,
  ]);

  const handleSearchChange = (event: ChangeEvent<HTMLInputElement>) => {
    setSearch(event.target.value);
    app.resetCategories();
  };

  const categoriesString = useMemo(() => {
    let value = "";

    if (app.selectedCategories.first !== null) {
      value = t("categories." + app.selectedCategories.first.Code + ".title");
    }

    if (app.selectedCategories.second !== null) {
      value +=
        " > " +
        t("categories." + app.selectedCategories.second.Code + ".title");
    }

    if (app.selectedCategories.third !== null) {
      value +=
        " > " + t("categories." + app.selectedCategories.third.Code + ".title");
    }

    return value;
  }, [
    app.selectedCategories.first,
    app.selectedCategories.second,
    app.selectedCategories.third,
    t,
  ]);

  const toggleSortPrice = () => {
    setSortPrice((prev) => {
      if (prev === null) {
        return "ASC";
      } else if (prev === "ASC") {
        return "DESC";
      } else {
        return null;
      }
    });
  };

  return (
    <div className={"container mx-auto pb-12 min-h-screen"}>
      <div className={"flex flex-row flex-nowrap gap-8 h-full min-h-screen"}>
        <div
          className={"min-w-[320px] max-w-[340px] pt-8 flex-shrink-0 relative"}
        >
          <div
            className={
              "bg-white flex flex-col max-h-full rounded-md border-1 border-solid border-gray-100 py-2 sticky left-0 top-8"
            }
          >
            <h1 className={"py-2 px-4 font-bold"}>{t("home.categories")}</h1>
            <div className={"flex flex-col max-h-full h-full overflow-y-auto"}>
              {app.categories.map((category, index) => (
                <div
                  key={index}
                  className={
                    "flex flex-col w-full border-t-1 border-solid border-gray-300 first-of-type:border-t-0"
                  }
                >
                  <FilterButton
                    selected={
                      app.selectedCategories.first?.Code === category.Code
                    }
                    category={category}
                    level={1}
                  />
                  <AnimatePresence>
                    {category.ChildCategories.length > 0 &&
                      app.selectedCategories.first?.Code === category.Code && (
                        <motion.div
                          initial={{ opacity: 0, height: 0 }}
                          animate={{ opacity: 1, height: "auto" }}
                          exit={{ opacity: 0, height: 0 }}
                          className={"w-full"}
                        >
                          {category.ChildCategories.map(
                            (childCategory, index) => (
                              <div key={index} className={"flex flex-col"}>
                                <FilterButton
                                  selected={
                                    app.selectedCategories.second?.Code ===
                                    childCategory.Code
                                  }
                                  category={childCategory}
                                  level={2}
                                />
                                <AnimatePresence>
                                  {childCategory.ChildCategories.length > 0 &&
                                    app.selectedCategories.second?.Code ===
                                      childCategory.Code && (
                                      <motion.div
                                        initial={{ opacity: 0, height: 0 }}
                                        animate={{ opacity: 1, height: "auto" }}
                                        exit={{ opacity: 0, height: 0 }}
                                        className={"w-full"}
                                      >
                                        {childCategory.ChildCategories.map(
                                          (childChildCategory, index) => (
                                            <div
                                              className={"flex flex-col"}
                                              key={index}
                                            >
                                              <FilterButton
                                                selected={
                                                  app.selectedCategories.third
                                                    ?.Code ===
                                                  childChildCategory.Code
                                                }
                                                category={childChildCategory}
                                                level={3}
                                              />
                                              <AnimatePresence>
                                                {childChildCategory
                                                  .ChildCategories.length > 0 &&
                                                  app.selectedCategories.second
                                                    ?.Code ===
                                                    childChildCategory.Code && (
                                                    <motion.div
                                                      initial={{
                                                        opacity: 0,
                                                        height: 0,
                                                      }}
                                                      animate={{
                                                        opacity: 1,
                                                        height: "auto",
                                                      }}
                                                      exit={{
                                                        opacity: 0,
                                                        height: 0,
                                                      }}
                                                      className={"w-full"}
                                                    >
                                                      {childChildCategory.ChildCategories.map(
                                                        (
                                                          childChildChildCategory,
                                                          index
                                                        ) => (
                                                          <FilterButton
                                                            key={index}
                                                            selected={
                                                              app
                                                                .selectedCategories
                                                                .third?.Code ===
                                                              childChildChildCategory.Code
                                                            }
                                                            category={
                                                              childChildChildCategory
                                                            }
                                                            level={4}
                                                          />
                                                        )
                                                      )}
                                                    </motion.div>
                                                  )}
                                              </AnimatePresence>
                                            </div>
                                          )
                                        )}
                                      </motion.div>
                                    )}
                                </AnimatePresence>
                              </div>
                            )
                          )}
                        </motion.div>
                      )}
                  </AnimatePresence>
                </div>
              ))}
            </div>
          </div>
        </div>
        <div className={"flex flex-nowrap flex-col w-full"}>
          <div
            className={
              "flex flex-row flex-nowrap w-full justify-between items-center sticky z-10 top-0 pt-8 pb-4 left-0 border-b-1 border-solid border-gray-300"
            }
            style={{ backgroundColor: "rgb(245,244,245)" }}
          >
            <div className={"flex flex-row flex-nowrap gap-4 items-center"}>
              <input
                className={
                  "bg-white rounded text-xs py-2 px-3 w-[300px] focus:w-[400px] transition-all duration-300"
                }
                placeholder={t("search")}
                value={search}
                onChange={handleSearchChange}
              />
              <p className={"text-sm"}>{t("home.sorting")}:</p>
              <button
                type={"button"}
                className={
                  "flex flex-row items-center justify-center gap-1 text-sm bg-white rounded px-2 py-1"
                }
                onClick={toggleSortPrice}
                title={
                  sortPrice !== null
                    ? t("home.sortPrice." + sortPrice)
                    : t("home.sortPrice.default")
                }
              >
                <span>{t("home.price")}</span>
                {sortPrice !== null ? (
                  <ChevronDownIcon
                    className={
                      "w-5 h-5 transition will-change-transform" +
                      (sortPrice === "DESC" ? " -rotate-180" : " rotate-0")
                    }
                  />
                ) : (
                  <MinusIcon className={"h-5 w-3"} />
                )}
              </button>
            </div>
            <p className={"flex flex-row text-sm flex-nowrap gap-4"}>
              {categoriesString}
            </p>
          </div>
          <div className={"flex flex-row flex-wrap gap-4 pt-4"}>
            {items.slice(0, showItems).map((item, index) => (
              <ArtikelComponent key={index} artikel={item} />
            ))}
          </div>
          {items.length > showItems && (
            <div className={"flex flex-row justify-center mt-8"}>
              <button
                onClick={() => setShowItems(showItems + 20)}
                className={
                  "bg-gray-100 hover:bg-gray-200 text-gray-500 px-4 py-2 rounded-md"
                }
              >
                {items.length - showItems} {t("home.showMore")}
              </button>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default Home;
