import { FC, useEffect, useMemo, useState } from "react";
import api from "../../api";
import { useTranslation } from "react-i18next";
import { PlusIcon, UserIcon, XMarkIcon } from "@heroicons/react/24/solid";
import { Link, useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import Portal from "../../components/portal";
import { AnimatePresence, motion } from "framer-motion";
import Backdrop from "../../components/backdrop";
import Input from "../../components/input";
import Checkbox from "../../components/checkbox";
import { ApiUserEntity, CreateApiUserEntity } from "../../index";
import { sleep } from "../../sleep";
import { classValidatorResolver } from "@hookform/resolvers/class-validator";
import CircularProgress from "../../components/circularprogress";

const Users: FC = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState<boolean>(false);
  const [users, setUsers] = useState<ApiUserEntity[]>([]);
  const { t } = useTranslation();
  const [search, setSearch] = useState<string>("");
  const [addModal, setAddModal] = useState<boolean>(false);
  const form = useForm<CreateApiUserEntity>({
    mode: "onChange",
    resolver: classValidatorResolver(CreateApiUserEntity),
    defaultValues: {
      orders: [],
      accepted_orders: [],
      active: true,
      created: undefined,
      deleted: null,
      updated: undefined,
      supervisor: false,
      supervisorUuid: null,
      debitor: "",
      email: "",
      deletedOrders: [],
      firstname: "",
      lastname: "",
      emailNotifications: true,
      password: "",
      uuid: undefined,
      passwordConfirmation: "",
      rejected_orders: [],
    },
  });

  useEffect(() => {
    let cancel = false;

    setLoading(true);

    api
      .get<ApiUserEntity[]>("/api-nonfood/my-users")
      .then((res) => {
        if (!cancel) {
          setUsers(res.data);
        }
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        if (!cancel) {
          setLoading(false);
        }
      });
  }, []);

  const data = useMemo(
    () =>
      users.filter((user) => {
        if (!search.length) {
          return true;
        }
        const firstname = user.firstname.toLowerCase();
        const lastname = user.lastname.toLowerCase();
        const email = user.email.toLowerCase();
        const searchLower = search.toLowerCase();
        return (
          firstname.includes(searchLower) ||
          lastname.includes(searchLower) ||
          email.includes(searchLower)
        );
      }),
    [search, users]
  );

  const submit = (data: ApiUserEntity) => {
    setLoading(true);
    Promise.all([
      api.post<ApiUserEntity>("/api-nonfood/my-users", data),
      sleep(800),
    ])
      .then((res) => {
        setUsers((prev) => [...prev, res[0].data]);
        setAddModal(false);
      })
      .catch((e) => console.log(e))
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <div className={"container max-w-screen-lg mx-auto py-8"}>
      <div className={"flex items-center gap-1"}>
        <Link
          className={"text-blue-500 text-sm hover:underline"}
          to={"/account"}
        >
          {t("myAccount")}
        </Link>
        <span>•</span>
        <span className={"text-sm"}>{t("users.title")}</span>
      </div>
      <h4 className={"text-3xl font-bold mb-4"}>{t("users.title")}</h4>
      <div
        className={
          "flex flex-row flex-nowrap justify-between items-center py-2"
        }
      >
        <input
          className={
            "py-2 px-3 text-sm max-w-md focus:max-w-lg w-full transition-all will-change-transform rounded outline-none"
          }
          value={search}
          onChange={(e) => setSearch(e.currentTarget.value)}
          type={"search"}
          placeholder={t("search")}
        />
        <button
          className={
            "bg-gray-100 hover:bg-gray-200 rounded transition-colors flex flex-row justify-center items-center gap-1 px-3 py-2"
          }
          type={"button"}
          onClick={() => setAddModal(true)}
        >
          <PlusIcon className={"h-4 w-4"} />
          {t("users.add")}
        </button>
      </div>
      {loading ? (
        <CircularProgress />
      ) : (
        <div className={"flex flex-row flex-wrap gap-4 w-full"}>
          {data.map((user, index) => (
            <div
              key={index}
              className={
                "border-1 border-solid border-gray-300 rounded min-w-fit max-w-sm grow cursor-pointer hover:scale-105 transition-all will-change-transform"
              }
              onClick={() => navigate((user.uuid || "") + "?tab=personal")}
            >
              <div
                className={
                  "flex flex-row flex-nowrap justify-center items-center p-4"
                }
              >
                <UserIcon className={"h-12 w-12 text-gray-500"} />
              </div>
              <div className={"flex flex-col px-4 pb-4"}>
                <p className={"text-center"}>
                  <i>
                    {user.firstname} {user.lastname}
                  </i>
                  <br />
                  {user.email}
                </p>
              </div>
            </div>
          ))}
        </div>
      )}
      <Portal wrapperId={"user-portal"}>
        <AnimatePresence>
          {addModal && (
            <Backdrop onClick={() => setAddModal(false)}>
              <motion.div
                className={
                  "bg-white rounded absolute top-1/2 left-1/2 p-4 min-w-[600px] max-w-[90vw] will-change-transform"
                }
                initial={{ x: "-50%", y: "-10%" }}
                animate={{ x: "-50%", y: "-50%" }}
                exit={{ x: "-50%", y: "-10%" }}
                onClick={(e) => e.stopPropagation()}
              >
                <form onSubmit={form.handleSubmit(submit)}>
                  <div className={"flex flex-col gap-4 flex-nowrap"}>
                    <div
                      className={
                        "flex flex-row flex-nowrap justify-between items-center"
                      }
                    >
                      <h1>Benutzer erstellen</h1>
                      <button
                        className={
                          "flex flex-row flex-nowrap justify-center items-center gap-1 rounded bg-gray-100 hover:bg-gray-200 transition-colors py-2 px-3 text-sm"
                        }
                        type={"button"}
                        onClick={() => setAddModal(false)}
                      >
                        <span>Schließen</span>
                        <XMarkIcon className={"h-4 w-4"} />
                      </button>
                    </div>
                    <Input
                      {...form.register("firstname")}
                      label={t("users.firstname.label")}
                      placeholder={t("users.firstname.placeholder")}
                      required
                      errors={form.formState.errors}
                    />
                    <Input
                      {...form.register("lastname")}
                      label={t("users.lastname.label")}
                      placeholder={t("users.lastname.placeholder")}
                      required
                      errors={form.formState.errors}
                    />
                    <Input
                      {...form.register("email")}
                      type={"email"}
                      label={t("users.email.label")}
                      placeholder={t("users.email.placeholder")}
                      required
                      errors={form.formState.errors}
                    />
                    <Input
                      type={"password"}
                      {...form.register("password")}
                      label={t("users.password.label")}
                      placeholder={t("users.password.placeholder")}
                      required
                      errors={form.formState.errors}
                    />
                    <Input
                      type={"password"}
                      {...form.register("passwordConfirmation")}
                      label={t("users.passwordConfirmation.label")}
                      placeholder={t("users.passwordConfirmation.placeholder")}
                      required
                      errors={form.formState.errors}
                    />
                    <Checkbox
                      onChange={(e) => form.setValue("emailNotifications", e)}
                      checked={form.watch("emailNotifications")}
                      label={t("users.emailNotifications.label")}
                      errors={form.formState.errors}
                      name={"emailNotifications"}
                    />
                    <Checkbox
                      onChange={(e) => form.setValue("active", e)}
                      checked={form.watch("active")}
                      label={"Login aktiv?"}
                      errors={form.formState.errors}
                      name={"active"}
                    />
                    <div>
                      <button
                        type={"submit"}
                        className={"text-white bg-orange-400 rounded px-4 py-2"}
                      >
                        Speichern
                      </button>
                    </div>
                  </div>
                </form>
              </motion.div>
            </Backdrop>
          )}
        </AnimatePresence>
      </Portal>
    </div>
  );
};

export default Users;
