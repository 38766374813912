import { FC, useMemo } from "react";
import { useParams } from "react-router-dom";
import Item from "../components/item";
import { useTranslation } from "react-i18next";
import { getShopCategoryItems, Artikel, useApp } from "../useAppProvider";

const Search: FC = () => {
  const { value } = useParams();
  const { t } = useTranslation();
  const app = useApp();

  const items = useMemo(() => {
    if (!value) return [];

    const items: Artikel[] = [];

    for (let i = 0; i < app.categories.length; i++) {
      const categoryItems = getShopCategoryItems(app.categories[i]);
      items.push(
        ...categoryItems.filter((item) => {
          const search = value.toLowerCase();
          const no = item.No.toLowerCase();
          const description = item.Description.toLowerCase();
          const vendor = item.AMA_VendorName.toLowerCase();
          return (
            no.indexOf(search) > -1 ||
            description.indexOf(search) > -1 ||
            vendor.indexOf(search) > -1
          );
        })
      );
    }

    return items;
  }, [app.categories, value]);

  return (
    <div className="container mx-auto py-4">
      <h2 className={"text-4xl"}>{t("search")}</h2>
      <div className={"border-t-1 border-gray-400 my-4 w-full h-0"} />
      <div className="grid grid-cols-5 gap-4">
        {items.map((item, index) => (
          <Item item={item} key={index} />
        ))}
      </div>
    </div>
  );
};

export default Search;
