import { FC, useEffect, useState } from "react";
import Input from "../../components/input";
import Checkbox from "../../components/checkbox";
import { ApiUserEntity, PasswordForm } from "../../index";
import { useForm } from "react-hook-form";
import { classValidatorResolver } from "@hookform/resolvers/class-validator";
import { sleep } from "../../sleep";
import api from "../../api";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import CircularProgress from "../../components/circularprogress";

const Settings: FC = () => {
  const { t } = useTranslation();
  const [loading, setLoading] = useState<boolean>(false);
  const form = useForm<ApiUserEntity>({
    resolver: classValidatorResolver(ApiUserEntity),
    mode: "onChange",
  });
  const passwordForm = useForm<PasswordForm>({
    mode: "onChange",
    resolver: classValidatorResolver(PasswordForm),
  });

  useEffect(() => {
    let cancel = false;

    setLoading(true);
    Promise.all([api.get<ApiUserEntity>("/api-nonfood/my-user"), sleep(800)])
      .then((res) => {
        if (!cancel) {
          form.reset(res[0].data);
          passwordForm.setValue("uuid", res[0].data.uuid || "");
        }
      })
      .catch((e) => console.log(e))
      .finally(() => {
        if (!cancel) {
          setLoading(false);
        }
      });

    return () => {
      cancel = true;
    };
  }, [form, passwordForm]);

  const submit = (data: ApiUserEntity) => {
    setLoading(true);
    Promise.all([
      api.put<ApiUserEntity>("/api-nonfood/my-user", data),
      sleep(800),
    ])
      .then((res) => {
        form.reset(res[0].data);
      })
      .catch((e) => console.log(e))
      .finally(() => setLoading(false));
  };

  const submitPassword = (data: PasswordForm) => {
    setLoading(true);
    Promise.all([
      api.post<ApiUserEntity>("/api-nonfood/my-user/password", data),
      sleep(800),
    ])
      .then(() => {
        passwordForm.setValue("password", "");
        passwordForm.setValue("passwordConfirmation", "");
      })
      .catch((e) => console.log(e))
      .finally(() => setLoading(false));
  };

  return (
    <div className={"container max-w-screen-lg mx-auto py-8"}>
      <div className={"flex items-center gap-1"}>
        <Link
          className={"text-blue-500 text-sm hover:underline"}
          to={"/account"}
        >
          {t("myAccount")}
        </Link>
        <span>•</span>
        <span className={"text-sm"}>{t("settings.title")}</span>
      </div>
      <h4 className={"text-3xl font-bold mb-4"}>{t("settings.title")}</h4>
      {loading && (
        <div
          className={
            "w-full h-[400px] flex flex-row justify-center items-center"
          }
        >
          <CircularProgress />
        </div>
      )}
      {!loading && (
        <div className={"flex flex-col gap-8"}>
          <form onSubmit={form.handleSubmit(submit)}>
            <div className={"flex flex-col gap-4 flex-nowrap"}>
              <Input
                {...form.register("firstname")}
                label={"Vorname"}
                errors={form.formState.errors}
                required
              />
              <Input
                {...form.register("lastname")}
                label={"Nachname"}
                errors={form.formState.errors}
                required
              />
              <Input
                {...form.register("email")}
                type={"email"}
                label={"E-Mail"}
                errors={form.formState.errors}
                required
              />
              <Checkbox
                onChange={(e) => form.setValue("emailNotifications", e)}
                checked={form.watch("emailNotifications")}
                label={"E-Mail Benachrichtigungen senden?"}
                errors={form.formState.errors}
                name={"emailNotifications"}
              />
              <Checkbox
                onChange={(e) => form.setValue("active", e)}
                checked={form.watch("active")}
                label={"Login aktiv?"}
                errors={form.formState.errors}
                name={"active"}
              />
              <div className={"flex flex-row"}>
                <button
                  type={"submit"}
                  className={"text-white bg-orange-400 rounded px-4 py-2"}
                >
                  Speichern
                </button>
              </div>
            </div>
          </form>
          <form onSubmit={passwordForm.handleSubmit(submitPassword)}>
            <div className={"flex flex-col flex-nowrap gap-4"}>
              <Input
                type={"password"}
                {...passwordForm.register("password")}
                label={"Neues Passwort"}
                placeholder={"Neues Passwort"}
                errors={passwordForm.formState.errors}
              />
              <Input
                type={"password"}
                {...passwordForm.register("passwordConfirmation")}
                label={"Neues Passwort wiederholen"}
                placeholder={"Neues Passwort wiederholen"}
                errors={passwordForm.formState.errors}
              />
              <div className={"flex flex-row"}>
                <button
                  type={"submit"}
                  className={"text-white bg-orange-400 rounded px-4 py-2"}
                >
                  Speichern
                </button>
              </div>
            </div>
          </form>
        </div>
      )}
    </div>
  );
};

export default Settings;
