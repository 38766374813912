import { FC } from "react";
import { Link } from "react-router-dom";

const Footer: FC = () => {
  return (
    <footer>
      <div className="w-full py-8 bg-gray-200">
        <div className="container mx-auto py-12 grid grid-cols-2">
          <div className={"col-span-1"}>
            <h5 className={"text-md font-bold"}>Zahlungsarten</h5>
            <p>Nur Rechnung möglich</p>
          </div>
          <div className={"col-span-1"}>
            <h5 className={"text-md font-bold"}>Zahlungsarten</h5>
            <p>Nur Rechnung möglich</p>
          </div>
          <div className={"col-span-1"}>
            <h5 className={"text-md font-bold"}>Zahlungsarten</h5>
            <p>Nur Rechnung möglich</p>
          </div>
          <div className={"col-span-1"}>
            <h5 className={"text-md font-bold"}>Zahlungsarten</h5>
            <p>Nur Rechnung möglich</p>
          </div>
        </div>
      </div>
      <div className="py-8">
        <div className="container mx-auto flex flex-row justify-center text-xs">
          © 2022 gourmetta GmbH & CO. KG - Gastronomiebedarf -{" "}
          <Link to="/agb" className="hover:underline">
            AGB
          </Link>{" "}
          -{" "}
          <Link to="/datenschutz" className="hover:underline">
            Datenschutz
          </Link>{" "}
          -{" "}
          <Link to="/impressum" className="hover:underline">
            Impressum
          </Link>
          -{" "}
          <Link to="/presse" className="hover:underline">
            Presse
          </Link>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
