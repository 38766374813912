import { FC } from "react";
import { Link } from "react-router-dom";
import defaultImage from "../assets/default.png";
import { Artikel, useApp } from "../useAppProvider";
import { formatEuro } from "../currency";

interface ItemProps {
  item: Artikel;
}

const Item: FC<ItemProps> = ({ item }) => {
  const app = useApp();

  return (
    <div className="col-span-1 border-1 border-gray-200 flex flex-col rounded-md overflow-hidden relative shadow-none hover:shadow-md transition-shadow">
      <Link
        to={"/categories/" + item.category + "/" + item.No}
        title={item.Description}
      >
        <div className="w-full flex flex-col items-center gap-2">
          <div
            style={{ backgroundImage: "url(" + defaultImage + ")" }}
            className="w-full bg-cover bg-center bg-no-repeat h-[150px]"
          />
          <div className="p-4 w-full flex flex-col h-full">
            <h4 className="font-bold text-sm text-left w-full">
              {item.Description}
            </h4>
            <h6 className={"text-sm text-left w-full"}>{item.Description2}</h6>
            {app.loggedIn && (
              <div className="mt-auto text-right">
                {formatEuro(item.Unit_Price || 0)}
              </div>
            )}
          </div>
        </div>
      </Link>
    </div>
  );
};

export default Item;
