import React, { FC, useEffect, useMemo, useState } from "react";
import { motion } from "framer-motion";
import { PhotoIcon, XMarkIcon } from "@heroicons/react/24/solid";
import { TrashIcon } from "@heroicons/react/24/outline";
import Backdrop from "./backdrop";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { Artikel, artikelDescription, useApp } from "../useAppProvider";
import { formatEuro } from "../currency";
import api from "../api";

interface CartItemProps {
  artikel: Artikel;
  index: number;
}

const CartItem: FC<CartItemProps> = (props) => {
  const app = useApp();
  const [img, setImg] = useState<string | null>(null);
  const [loading, setLoading] = useState<boolean>(false);

  useEffect(() => {
    let cancel = false;

    setLoading(true);
    api
      .get<string | void>("/api-nonfood/artikel/" + props.artikel.No + "/image")
      .then((res) => {
        if (!cancel && res.data) {
          setImg(res.data);
        }
      })
      .finally(() => {
        if (!cancel) {
          setLoading(false);
        }
      });

    return () => {
      cancel = true;
    };
  }, [props.artikel.No]);

  const price = useMemo(() => {
    if (
      typeof props.artikel.quantity !== "undefined" &&
      props.artikel.quantity !== null
    ) {
      return formatEuro(
        props.artikel.quantity * props.artikel.Last_Direct_Cost
      );
    } else {
      return formatEuro(props.artikel.Last_Direct_Cost);
    }
  }, [props.artikel.Last_Direct_Cost, props.artikel.quantity]);

  return (
    <motion.li
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ delay: props.index * 0.1 }}
    >
      <div className={"flex flex-row flex-nowrap gap-2"}>
        <div
          style={{ backgroundImage: "url(" + img + ")" }}
          className={
            "h-[100px] min-h-[100px] bg-no-repeat bg-center bg-contain min-w-[100px] max-w-[100px] w-full grow" +
            (loading ? " bg-gray-100 animate-pulse" : " bg-white")
          }
        >
          {img === null && <PhotoIcon className={"w-12 h-12"} />}
        </div>
        <div className={"flex flex-col grow"}>
          <h4 className={"text-xs text-gray-400"}>{props.artikel.No}</h4>
          <h2 className={"font-bold text-ellipsis overflow-hidden text-sm"}>
            {artikelDescription(props.artikel)}
          </h2>
          <div className={"flex flex-row w-full mt-auto text-xs"}>
            <h4>
              {props.artikel.quantity} Stück á{" "}
              <b>{formatEuro(props.artikel.Last_Direct_Cost)}</b>
            </h4>
            <h4 className={"ml-auto font-bold"}>{price}</h4>
          </div>
        </div>
        <div>
          <button
            type={"button"}
            className={"rounded hover:bg-gray-100 p-1"}
            onClick={() => app.removeCartItem(props.artikel)}
          >
            <TrashIcon className={"w-5 h-5"} />
          </button>
        </div>
      </div>
    </motion.li>
  );
};

const Cart: FC = () => {
  const app = useApp();
  const { t } = useTranslation();

  const price = useMemo(() => {
    let price = 0;
    for (let i = 0; i < app.cart.length; i++) {
      price += app.cart[i].quantity! * (app.cart[i].Last_Direct_Cost || 0);
    }
    return price;
  }, [app.cart]);
  const navigate = useNavigate();

  return (
    <Backdrop onClick={() => app.setCartModal(false)}>
      <motion.div
        className="fixed top-0 cursor-default right-0 bg-white h-screen bottom-0 flex flex-col gap-4 will-change-transform"
        onClick={(e) => e.stopPropagation()}
        transition={{ damping: 100, stiffness: 200 }}
        initial={{ transform: "translateX(100%)" }}
        animate={{ transform: "translateX(0%)" }}
        exit={{ transform: "translateX(100%)" }}
        style={{ boxShadow: "0 0 16px 0 rgb(0 0 0 / 16%)", width: 480 }}
      >
        <div className={"flex flex-row w-full items-center p-6"}>
          <h4 className={"font-bold"}>
            {t("cart")}{" "}
            <span style={{ color: "#888", fontWeight: "normal" }}>
              ({app.cart.length})
            </span>
          </h4>
          <button
            onClick={() => app.setCartModal(false)}
            className={"bg-transparent border-none p-2 ml-auto"}
          >
            <XMarkIcon className={"w-5 h-5"} />
          </button>
        </div>
        <ul className={"flex flex-col gap-4 px-6 max-h-[80%] overflow-y-auto"}>
          {app.cart.map((item, index) => (
            <CartItem index={index} artikel={item} key={index} />
          ))}
        </ul>
        <div
          className={"border-t-1 border-solid border-gray-200 h-0 w-full mx-6"}
        />
        <div className={"px-6"}>
          <p className={"font-bold text-sm"}>
            {t("inTotal")} {formatEuro(price)}
          </p>
        </div>
        <div className={"mt-auto flex flex-col gap-2 p-6"}>
          <button
            onClick={() => {
              navigate("/overview");
              app.setCartModal(false);
            }}
            className={"bg-green-600 py-3 rounded-sm text-white"}
          >
            {t("toCheckout")}
          </button>
        </div>
      </motion.div>
    </Backdrop>
  );
};

export default Cart;
