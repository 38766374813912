import { FC, useEffect, useState } from "react";
import api from "../api";
import defaultImage from "../assets/default.png";
import { formatEuro } from "../currency";
import { ApiOrderItemEntity } from "../index";

interface OrderItemProps {
  artikel: ApiOrderItemEntity;
  edit?: boolean;
  onChange?: (quantity: number, uuid: string) => void;
  handleRemove?: (uuid: string) => void;
}

const OrderItem: FC<OrderItemProps> = ({
  artikel,
  edit,
  onChange,
  handleRemove,
}) => {
  const [img, setImg] = useState<string | null>(null);
  const [loading, setLoading] = useState<boolean>(false);

  useEffect(() => {
    let cancel = false;

    setLoading(true);
    api
      .get<string | void>("/api-nonfood/artikel/" + artikel.No + "/image")
      .then((res) => {
        if (!cancel) {
          if (res.data) {
            setImg(res.data);
          } else {
            setImg(defaultImage);
          }
        }
      })
      .catch(() => {
        if (!cancel) {
          setImg(defaultImage);
        }
      })
      .finally(() => {
        if (!cancel) {
          setLoading(false);
        }
      });

    return () => {
      cancel = true;
    };
  }, [artikel.No]);

  return (
    <div className={"flex flex-row flex-nowrap gap-4"}>
      <div
        className={
          "w-20 h-20 rounded bg-center bg-contain bg-no-repeat" +
          (loading ? " animate-pulse bg-gray-100" : " bg-white")
        }
        style={{ backgroundImage: "url(" + img + ")" }}
      />
      <div className={"flex flex-col w-full"}>
        <p className={"text-xs"}>Art.Nr.: {artikel.No}</p>
        <p className={"text-sm font-bold"}>{artikel.Description}</p>
        <div
          className={
            "mt-auto text-sm flex flex-row items-center flex-nowrap gap-4 w-full"
          }
        >
          <div className={"flex flex-row gap-1 items-center"}>
            {edit ? (
              <input
                value={artikel.quantity}
                onChange={(e) =>
                  onChange && onChange(parseInt(e.target.value), artikel.uuid)
                }
                className={"py-2 px-4 text-sm rounded w-[100px]"}
                type={"number"}
              />
            ) : (
              <span>{artikel.quantity} Stück a</span>
            )}

            <b>{formatEuro(artikel.Last_Direct_Cost)}</b>
          </div>
          <div className={"grow"} />
          <p>
            Gesamtbetrag{" "}
            {formatEuro(artikel.Last_Direct_Cost * artikel.quantity)}
          </p>
          {edit && (
            <button
              onClick={() => {
                if (handleRemove !== undefined) {
                  handleRemove(artikel.uuid);
                }
              }}
              type={"button"}
              className={"text-red-500 hover:underline"}
            >
              Entfernen
            </button>
          )}
        </div>
      </div>
    </div>
  );
};

export default OrderItem;
