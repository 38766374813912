import React, { FC } from "react";
import { motion } from "framer-motion";
import { useNavigate } from "react-router-dom";
import Backdrop from "./backdrop";
import { useTranslation } from "react-i18next";
import { useApp } from "../useAppProvider";

const LogoutQuestion: FC = () => {
  const app = useApp();
  const navigate = useNavigate();
  const { t } = useTranslation();

  const handleLogut = async () => {
    await app.logout();
    app.setLogoutModal(false);
    navigate("/logout");
  };

  return (
    <Backdrop onClick={() => app.setLogoutModal(false)}>
      <motion.div
        initial={{ scale: 0.9 }}
        animate={{ scale: 1 }}
        exit={{ scale: 0.9 }}
        className={"bg-white rounded-md shadow-md py-4 px-6"}
        onClick={(e) => e.stopPropagation()}
      >
        <h5 className={"text-lg"}>{t("logoutConfirm")}</h5>
        <div className={"flex flex-row justify-between mt-4"}>
          <button
            className={"bg-gray-200 rounded-md py-2 px-3 hover:bg-gray-300"}
          >
            {t("cancel")}
          </button>
          <button
            onClick={handleLogut}
            className={"bg-orange-300 rounded-md py-2 px-3 hover:bg-orange-400"}
          >
            {t("logout")}
          </button>
        </div>
      </motion.div>
    </Backdrop>
  );
};

export default LogoutQuestion;
