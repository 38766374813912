import { useTranslation } from "react-i18next";
import React, {
  createContext,
  Dispatch,
  FC,
  ReactNode,
  SetStateAction,
  useContext,
  useEffect,
  useState,
} from "react";
import api from "./api";
import {
  ApiOrderEntity,
  ApiOrderItemEntity,
  LoginResponse,
  User,
} from "./index";
import i18n from "i18next";

export const getShopCategoryItems = (category: ApiShopCategory): Artikel[] => {
  const response = [...category.Items];

  if (category.ChildCategories.length > 0) {
    for (let i = 0; i < category.ChildCategories.length; i++) {
      response.push(...getShopCategoryItems(category.ChildCategories[i]));
    }
  }
  return response;
};

export const artikelDescription = (artikel: Artikel): string => {
  switch (i18n.language) {
    case "de-DE":
      return artikel.Description;
    case "en-EN": {
      for (let i = 0; i < artikel.translations.length; i++) {
        if (artikel.translations[i].Language_Code === "de-DE") {
          return artikel.translations[i].Description;
        }
      }
      return artikel.Description;
    }
    case "fr-FR": {
      for (let i = 0; i < artikel.translations.length; i++) {
        if (artikel.translations[i].Language_Code === "FRANZ") {
          return artikel.translations[i].Description;
        }
      }
      return artikel.Description;
    }
    default:
      return artikel.Description;
  }
};

export const artikelDescription2 = (artikel: Artikel): string => {
  switch (i18n.language) {
    case "de-DE":
      return artikel.Description2;
    case "en-EN": {
      for (let i = 0; i < artikel.translations.length; i++) {
        if (artikel.translations[i].Language_Code === "de-DE") {
          return artikel.translations[i].Description_2;
        }
      }
      return artikel.Description2;
    }
    case "fr-FR": {
      for (let i = 0; i < artikel.translations.length; i++) {
        if (artikel.translations[i].Language_Code === "FRANZ") {
          return artikel.translations[i].Description_2;
        }
      }
      return artikel.Description2;
    }
    default:
      return artikel.Description2;
  }
};

interface IUseAppProvider {
  user: User | null;
  logoutError: boolean;
  getCategoryCodeByName: (search?: string) => string | null;
  loggedIn: boolean;
  validating: boolean;
  logout: () => void;
  cartModal: boolean;
  wishlist: Artikel[];
  setCartModal: Dispatch<SetStateAction<boolean>>;
  addCartItem: (item: Artikel) => void;
  removeCartItem: (item: Artikel) => void;
  cart: Artikel[];
  categories: ApiShopCategory[];
  loading: boolean;
  warenkorbMessage: boolean;
  getCategoryTitle: (category: ApiShopCategory) => string;
  login: (email: string, password: string) => Promise<string>;
  submitOrder: () => Promise<void>;
  submitOrderSuccess: boolean;
  submitOrderError: boolean;
  setSubmitOrderSuccess: Dispatch<SetStateAction<boolean>>;
  setSubmitOrderError: Dispatch<SetStateAction<boolean>>;
  orders: ApiOrderEntity[];
  logoutModal: boolean;
  setLogoutModal: Dispatch<React.SetStateAction<boolean>>;
  validate: () => void;
  prices: BCEinkaufspreis[];
  cartArtikelChange: (artikel: Artikel) => void;
  selectedCategories: SelectedCategoriesType;
  toggleCategory: (category: ApiShopCategory) => void;
  resetCategories: () => void;
  setLogoutError: Dispatch<SetStateAction<boolean>>;
}

export interface LocalCartItem {
  uuid: string;
  items: Artikel[];
}

export interface LocalWishlistItem {
  uuid: string;
  items: Artikel[];
}

export interface Unit {
  Item_No: string;
  Code: string;
  Qty_per_Unit_of_Measure: number;
  AMA_UoM_Net_Filling_Weight: string;
  AMA_Net_Filling_Weight: number;
  Height: number;
  Width: number;
  Length: number;
  Cubage: number;
  Weight: number;
  ItemUnitOfMeasure: string;
  ItemBaseUOMQtyPrecision: number;
}

export interface SyntheticArtikel {
  No: string;
  Description: string;
  Description2: string;
  Last_Direct_Cost: number;
  Base_Unit_of_Measure: string;
  GTIN: string;
  Item_Category_Code: string;
  Search_Description: string;
  Net_Weight: number;
  Gross_Weight: number;
  Vendor_No: string;
  AMA_VendorName: string;
  Vendor_Item_No: string;
  Unit_Price?: number;
  quantity?: number;
  category?: string;
  unit?: ApiShopArtikelEinheit;
}

export interface SyntheticCateogry {
  ParentCategory: string | null;
  Code: string;
  Description: string;
  ChildCategories: SyntheticCateogry[];
  Items: SyntheticArtikel[];
}

export interface ApiShopCategory extends SyntheticCateogry {
  Items: ApiShopArtikel[];
  ChildCategories: ApiShopCategory[];
}

export interface ApiShopArtikel extends SyntheticArtikel {
  translations: ApiShopTranslation[];
  unit?: ApiShopArtikelEinheit;
}

export interface ApiShopArtikelEinheit {
  Item_No: string;
  Code: string;
  Qty_per_Unit_of_Measure: number;
  AMA_UoM_Net_Filling_Weight: string;
  AMA_Net_Filling_Weight: number;
  Height: number;
  Width: number;
  Length: number;
  Cubage: number;
  Weight: number;
  ItemUnitOfMeasure: string;
  ItemBaseUOMQtyPrecision: number;
}

export interface ApiShopTranslation {
  Item_No: string;
  Variant_Code: string;
  Language_Code: string;
  Description: string;
  Description_2: string;
}

export interface Translation {
  Item_No: string;
  Variant_Code: string;
  Language_Code: string;
  Description: string;
  Description_2: string;
}

export interface Artikel {
  No: string;
  Description: string;
  Description2: string;
  Last_Direct_Cost: number;
  Base_Unit_of_Measure: string;
  GTIN: string;
  Item_Category_Code: string;
  Search_Description: string;
  Net_Weight: number;
  Gross_Weight: number;
  Vendor_No: string;
  AMA_VendorName: string;
  Vendor_Item_No: string;
  Unit_Price?: number;
  quantity?: number;
  category?: string;
  unit?: Unit;
  translations: Translation[];
}

export interface BCEinkaufspreis {
  Item_No: string;
  Vendor_No: string;
  Starting_Date: string;
  Currency_Code: string;
  Variant_Code: string;
  Unit_of_Measure_Code: string;
  Minimum_Quantity: number;
  VendNoFilterCtrl: string;
  ItemNoFIlterCtrl: string;
  StartingDateFilter: string;
  AMA_Vendor_Name: string;
  AMA_Use_in_Bestprice_Comparison: boolean;
  AMA_Item_Description: string;
  Direct_Unit_Cost: number;
  Ending_Date: string;
  AMA_Agreed_Price: boolean;
  AMA_Agreed_Price_by: string;
  AMA_Agreed_Price_at: string;
  AMA_Tender: string;
  AMA_Price_Variance: number;
  AMA_Price_to_compare: number;
  AMA_Direct_Unit_Cost_Base_UoM: number;
}

type SelectedCategoriesType = {
  first: ApiShopCategory | null;
  second: ApiShopCategory | null;
  third: ApiShopCategory | null;
};

export const calculateSum = (items: ApiOrderItemEntity[]) => {
  let sum = 0;
  for (let i = 0; i < items.length; i++) {
    sum += items[i].Last_Direct_Cost * items[i].quantity;
  }
  return sum;
};

const AppContext = createContext({} as IUseAppProvider);
export const useApp = () => useContext(AppContext);
const useAppProvider = () => {
  const { t } = useTranslation();
  const [orders, setOrders] = useState<ApiOrderEntity[]>([]);
  const [loggedIn, setLoggedIn] = useState<boolean>(false);
  const [user, setUser] = useState<User | null>(null);
  const [validating, setValidating] = useState<boolean>(true);
  const [wishlist, setWishlist] = useState<Artikel[]>([]);
  const [cartModal, setCartModal] = useState<boolean>(false);
  const [cart, setCart] = useState<Artikel[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [warenkorbMessage, setWarenkorbMessage] = useState<boolean>(false);
  const [submitOrderSuccess, setSubmitOrderSuccess] = useState<boolean>(false);
  const [submitOrderError, setSubmitOrderError] = useState<boolean>(false);
  const [logoutModal, setLogoutModal] = useState<boolean>(false);
  const [logoutError, setLogoutError] = useState<boolean>(false);
  const [selectedCategories, setSelectedCategories] =
    useState<SelectedCategoriesType>({
      first: null,
      second: null,
      third: null,
    });

  const [categories, setCategories] = useState<ApiShopCategory[]>([]);
  const [prices, setPrices] = useState<BCEinkaufspreis[]>([]);

  useEffect(() => {
    let cancel = false;

    let localWishlist = localStorage.getItem("wishlist");
    if (localWishlist) {
      const localItems: LocalWishlistItem[] = JSON.parse(localWishlist);
      if (user) {
        for (let i = 0; i < localItems.length; i++) {
          if (localItems[i].uuid === user.uuid) {
            if (!cancel) {
              setWishlist(localItems[i].items);
            }
            break;
          }
        }
      } else {
        for (let i = 0; i < localItems.length; i++) {
          if (localItems[i].uuid === "") {
            if (!cancel) {
              setWishlist(localItems[i].items);
            }
            break;
          }
        }
      }
    }

    const localCart = localStorage.getItem("cart");
    if (localCart) {
      const localItems: LocalCartItem[] = JSON.parse(localCart);
      if (user) {
        for (let i = 0; i < localItems.length; i++) {
          if (localItems[i].uuid === user.uuid) {
            if (!cancel) {
              setCart(localItems[i].items);
            }
            break;
          }
        }
      }
      for (let i = 0; i < localItems.length; i++) {
        if (localItems[i].uuid === "") {
          if (!cancel) {
            setCart(localItems[i].items);
          }
          break;
        }
      }
    }

    return () => {
      cancel = true;
    };
  }, [loggedIn, user]);

  useEffect(() => {
    let cancel = false;

    if (!validating && loggedIn) {
      Promise.all([
        api.get<BCEinkaufspreis[]>("/api-nonfood/prices"),
        api.get<ApiOrderEntity[]>("/api-nonfood/orders"),
        api.get<User>("/api-nonfood/user"),
      ]).then((res) => {
        if (!cancel) {
          setPrices(res[0].data);
          setOrders(res[1].data);
          setUser(res[2].data);
        }
      });
    } else {
      setPrices([]);
      setOrders([]);
    }

    return () => {
      cancel = true;
    };
  }, [loggedIn, validating]);

  useEffect(() => {
    let cancel = false;

    api
      .get<ApiShopCategory[]>("/api-nonfood/categories")
      .then(async (res) => {
        if (!cancel) {
          setCategories(
            res.data.filter(
              (category) => getShopCategoryItems(category).length > 0
            )
          );
        }
      })
      .finally(() => {
        if (!cancel) {
          setLoading(false);
        }
      });

    return () => {
      cancel = true;
    };
  }, []);

  const cartArtikelChange = (artikel: Artikel): void => {
    const copy = [...cart];
    for (let i = 0; i < copy.length; i++) {
      if (copy[i].No === artikel.No) {
        copy[i] = artikel;
        break;
      }
    }
    setCart(copy);

    const localCart = localStorage.getItem("cart");

    if (localCart) {
      const localItems = JSON.parse(localCart) as LocalCartItem[];
      if (user) {
        for (let i = 0; i < localItems.length; i++) {
          if (localItems[i].uuid === user.uuid) {
            localItems[i].items = copy;
            localStorage.setItem("cart", JSON.stringify(localItems));
            break;
          }
        }
      } else {
        for (let i = 0; i < localItems.length; i++) {
          if (localItems[i].uuid === "") {
            localItems[i].items = copy;
            localStorage.setItem("cart", JSON.stringify(localItems));
            break;
          }
        }
      }
    } else {
      const item: LocalCartItem = {
        uuid: user ? user.uuid : "",
        items: copy,
      };
      localStorage.setItem("cart", JSON.stringify([item]));
    }
  };

  const validate = (): void => {
    const token = localStorage.getItem("token");

    if (!token || typeof token === "undefined" || String(token).length <= 0) {
      localStorage.removeItem("token");
      setLoggedIn(false);
      setValidating(false);
      return;
    }

    setLoggedIn(true);
    setValidating(false);
  };

  const login = async (email: string, password: string): Promise<string> =>
    api
      .post<LoginResponse>("/api-nonfood/login", { email, password })
      .then((response) => {
        localStorage.setItem("token", response.data.token);
        setUser({
          email: response.data.email,
          firstname: response.data.firstname,
          lastname: response.data.lastname,
          role: response.data.role,
          supervisor: response.data.supervisor,
          uuid: response.data.uuid,
        });
        setLoggedIn(true);

        const localWishlist = localStorage.getItem("wishlist");
        if (localWishlist) {
          const localItems: LocalWishlistItem[] = JSON.parse(localWishlist);
          const wishListItems: Artikel[] = [];

          let removeNonUserItem = false;

          for (let i = 0; i < localItems.length; i++) {
            if (localItems[i].uuid === "") {
              for (let x = 0; x < localItems[i].items.length; x++) {
                wishListItems.push(localItems[i].items[x]);
              }
            }
            if (localItems[i].uuid === response.data.uuid) {
              removeNonUserItem = true;
              for (let x = 0; x < localItems[i].items.length; x++) {
                wishListItems.push(localItems[i].items[x]);
              }
            }
          }

          if (removeNonUserItem) {
            for (let i = 0; i < localItems.length; i++) {
              if (localItems[i].uuid === "") {
                localItems.splice(i, 1);
                break;
              }
            }
          }

          for (let i = 0; i < localItems.length; i++) {
            if (localItems[i].uuid === response.data.uuid) {
              localItems[i].items = wishListItems;
              break;
            }
          }

          localStorage.setItem("wishlist", JSON.stringify(localItems));
        }

        const localCart = localStorage.getItem("cart");
        const cartItems: Artikel[] = [];
        if (localCart) {
          const localItems: LocalCartItem[] = JSON.parse(localCart);
          let removeNonUserItem = false;
          for (let i = 0; i < localItems.length; i++) {
            if (localItems[i].uuid === "") {
              for (let x = 0; x < localItems[i].items.length; x++) {
                cartItems.push(localItems[i].items[x]);
              }
            }
            if (localItems[i].uuid === response.data.uuid) {
              removeNonUserItem = true;
              for (let x = 0; x < localItems[i].items.length; x++) {
                cartItems.push(localItems[i].items[x]);
              }
            }
          }

          if (removeNonUserItem) {
            for (let i = 0; i < localItems.length; i++) {
              if (localItems[i].uuid === "") {
                localItems.splice(i, 1);
                break;
              }
            }
          }

          for (let i = 0; i < localItems.length; i++) {
            if (localItems[i].uuid === response.data.uuid) {
              localItems[i].items = cartItems;
              break;
            }
          }

          localStorage.setItem("cart", JSON.stringify(cartItems));
        }

        return "/";
      });

  const logout = async () => {
    try {
      await api.post("/api-nonfood/logout");
      localStorage.removeItem("token");
      setUser(null);
      setLoggedIn(false);
    } catch {
      setLogoutError(true);
    }
  };

  const addCartItem = (item: Artikel) => {
    const copy = [...cart];
    let alreadyAdded = false;

    for (let i = 0; i < copy.length; i++) {
      if (copy[i].No === item.No && typeof item.quantity === "number") {
        if (typeof copy[i].quantity === "undefined") {
          copy[i].quantity = item.quantity;
        }
        copy[i].quantity! += item.quantity;
        alreadyAdded = true;
        break;
      }
    }
    if (!alreadyAdded) {
      copy.unshift({ ...item, quantity: item.quantity || 1 });
    }
    setCart(copy);
    setWarenkorbMessage(true);
    setTimeout(() => setWarenkorbMessage(false), 3000);

    const localCart = localStorage.getItem("cart");

    if (localCart) {
      const localItems: LocalCartItem[] = JSON.parse(localCart);
      if (user) {
        for (let i = 0; i < localItems.length; i++) {
          if (localItems[i].uuid === user.uuid) {
            localItems[i].items = copy;
            localStorage.setItem("cart", JSON.stringify(localItems));
            return;
          }
        }
      } else {
        for (let i = 0; i < localItems.length; i++) {
          if (localItems[i].uuid === "") {
            localItems[i].items = copy;
            localStorage.setItem("cart", JSON.stringify(localItems));
            return;
          }
        }
      }
    } else {
      const item: LocalCartItem = {
        uuid: user ? user.uuid : "",
        items: copy,
      };
      localStorage.setItem("cart", JSON.stringify([item]));
    }
  };

  const removeCartItem = (item: Artikel) => {
    const copy = [...cart];
    for (let i = 0; i < copy.length; i++) {
      if (copy[i].No === item.No) {
        copy.splice(i, 1);

        setCart(copy);

        const localWishlist = localStorage.getItem("cart");
        if (localWishlist) {
          const localItems: LocalCartItem[] = JSON.parse(localWishlist);
          if (user) {
            for (let i = 0; i < localItems.length; i++) {
              if (localItems[i].uuid === user.uuid) {
                localItems[i].items = copy;
                localStorage.setItem("cart", JSON.stringify(localItems));
                return;
              }
            }
          } else {
            for (let i = 0; i < localItems.length; i++) {
              if (localItems[i].uuid === "") {
                localItems[i].items = copy;
                localStorage.setItem("cart", JSON.stringify(localItems));
                return;
              }
            }
          }
        }

        return;
      }
    }
  };

  const getCategoryTitle = (category: ApiShopCategory): string => {
    const name = category.Code.toLowerCase();
    return t("categories." + name + ".title");
  };

  const submitOrder = () =>
    api.post<void>("/api-nonfood/orders", { items: cart }).then(() => {
      setCart([]);
      api.get<ApiOrderEntity[]>("/api-nonfood/orders").then((res) => {
        setOrders(res.data);
      });
      const localCart = localStorage.getItem("cart");
      if (localCart) {
        const localItems = JSON.parse(localCart) as LocalCartItem[];
        for (let i = 0; i < localItems.length; i++) {
          if (localItems[i].uuid === user?.uuid) {
            localItems.splice(i, 1);
            break;
          }
        }
        localStorage.setItem("cart", JSON.stringify(localItems));
      }
    });

  const toggleCategory = (category: ApiShopCategory) => {
    const copy = { ...selectedCategories };

    if (copy.first?.Code === category.Code) {
      copy.first = null;
      copy.second = null;
      copy.third = null;
      setSelectedCategories(copy);
      return;
    }

    if (category.ParentCategory === null) {
      copy.first = category;
      copy.second = null;
      copy.third = null;
      setSelectedCategories(copy);
      return;
    }

    if (copy.first?.Code === category.ParentCategory) {
      if (copy.second?.Code === category.Code) {
        copy.second = null;
      } else {
        copy.second = category;
      }

      copy.third = null;
      setSelectedCategories(copy);
      return;
    }

    if (copy.first === null) {
      copy.first = category;
      setSelectedCategories(copy);
      return;
    }

    if (copy.second?.Code === category.Code) {
      copy.second = null;
      copy.third = null;
      setSelectedCategories(copy);
      return;
    }

    if (copy.second === null) {
      copy.second = category;
      setSelectedCategories(copy);
      return;
    }

    if (copy.third === null) {
      copy.third = category;
      setSelectedCategories(copy);
      return;
    }

    if (copy.second?.Code === category.Code) {
      copy.second = null;
      copy.third = null;
      setSelectedCategories(copy);
      return;
    }

    if (copy.first.Code === category.ParentCategory) {
      copy.second = category;
      copy.third = null;
      setSelectedCategories(copy);
      return;
    }

    if (copy.second.Code === category.ParentCategory) {
      copy.third = category;
      setSelectedCategories(copy);
      return;
    }
  };

  const resetCategories = () => {
    setSelectedCategories({
      first: null,
      second: null,
      third: null,
    });
  };

  const getCategoryCodeByName = (search?: string): string | null => {
    if (!search || !search.length) {
      return null;
    }
    const searchLower = search.toLocaleLowerCase();

    const searchCategories = (cats: ApiShopCategory[]): string | null => {
      for (let i = 0; i < cats.length; i++) {
        if (cats[i].Description.toLowerCase() === searchLower) {
          return cats[i].Code;
        }
        if (categories[i].ChildCategories.length > 0) {
          const child = searchCategories(cats[i].ChildCategories);
          if (child) {
            return child;
          }
        }
      }
      return null;
    };

    return searchCategories(categories);
  };

  return {
    getCategoryCodeByName,
    resetCategories,
    toggleCategory,
    logoutModal,
    cartModal,
    wishlist,
    cart,
    prices,
    categories,
    loggedIn,
    validating,
    user,
    loading,
    warenkorbMessage,
    logoutError,
    submitOrderSuccess,
    submitOrderError,
    orders,
    selectedCategories,
    cartArtikelChange,
    setLogoutError,
    setLogoutModal,
    submitOrder,
    addCartItem,
    removeCartItem,
    setCartModal,
    setUser,
    login,
    logout,
    validate,
    getCategoryTitle,
    setSubmitOrderSuccess,
    setSubmitOrderError,
  };
};

export const AppProvider: FC<{ children: ReactNode }> = ({ children }) => {
  const value = useAppProvider();
  return <AppContext.Provider value={value}>{children}</AppContext.Provider>;
};
