import React, { FC } from "react";
import { motion } from "framer-motion";

const CartMessage: FC = () => {
  return (
    <motion.div
      initial={{ x: "150%" }}
      animate={{ x: 0 }}
      exit={{ x: "150%" }}
      className={
        "z-50 fixed top-10 right-10 bg-white p-4 shadow-md rounded-md max-w-[250px]"
      }
    >
      Artikel wurde dem Warenkorb hinzugefügt
    </motion.div>
  );
};

export default CartMessage;
