import { FC } from "react";
import dayjs from "dayjs";
import { calculateSum, useApp } from "../../useAppProvider";
import { formatEuro } from "../../currency";
import { useTranslation } from "react-i18next";
import OrderItem from "../../components/orderitem";

const Orders: FC = () => {
  const app = useApp();
  const { t } = useTranslation();

  return (
    <div className={"container max-w-screen-lg mx-auto py-8"}>
      <h4 className={"text-3xl font-bold mb-4"}>{t("myOrders")}</h4>
      <div className={"flex flex-col gap-4"}>
        {app.orders.map((order) => (
          <div
            key={order.uuid}
            className={"rounded-md border-1 border-solid border-gray-300"}
          >
            <div
              className={
                "rounded-t-md bg-gray-200 p-3 flex flex-row flex-nowrap gap-8 sticky top-0 left-0"
              }
            >
              <div>
                <p className={"font-bold text-xs text-gray-600"}>
                  Bestellung aufgegeben
                </p>
                <p className={"text-xs"}>
                  {dayjs.utc(order.created).format("DD.MM.YYYY")}
                </p>
              </div>
              <div>
                <p className={"font-bold text-xs text-gray-600"}>Summe</p>
                <p className={"text-xs"}>
                  {formatEuro(calculateSum(order.items))}
                </p>
              </div>
              <div className={"ml-auto"}>
                <p className={"font-bold text-xs text-gray-600"}>
                  Bestellnummer
                </p>
                <p className={"text-xs"}>{order.uuid}</p>
              </div>
            </div>
            <div className={"p-4 flex flex-col gap-4"}>
              {order.items.map((item, index) => (
                <OrderItem key={index} artikel={item} />
              ))}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Orders;
