import {FC, ReactNode, useEffect} from "react";
import { motion} from "framer-motion";

interface BackdropProps {
  children?: ReactNode;
  onClick?: () => void;
}

const Backdrop: FC<BackdropProps> = ({ children, onClick }) => {
  useEffect(() => {
    document.documentElement.style.overflow = "hidden";

    return () => {
      document.documentElement.style.overflow = "auto";
    }
  }, [])

  return (
    <motion.div style={{ backgroundColor: "rgba(0,0,0,.3)"}} onClick={onClick} initial={{ opacity: 0 }} animate={{ opacity: 1 }} exit={{ opacity: 0}} className={"absolute flex flex-row justify-center items-center inset-0 w-full h-full z-50"}>
      {children}
    </motion.div>
  )
}

export default Backdrop;