import axios from "axios";

const api = axios.create({
  ...(process.env.REACT_APP_STAGE === "development"
    ? {
        baseURL: "http://localhost:3333",
      }
    : {}),
  ...(process.env.REACT_APP_STAGE === "production"
    ? {
        baseURL: "https://api.gourmetta.de",
      }
    : {}),
  ...(process.env.REACT_APP_STAGE === "staging"
    ? {
        baseURL: "https://rest.gourmetta.de",
      }
    : {}),
  withCredentials: true,
});

api.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem("token");
    config.headers = {
      ...config.headers,
      "Content-Type": "application/json",
      Accept: "application/json",
      ...(token && {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      }),
    };
    return config;
  },
  (error) => Promise.reject(error)
);

api.interceptors.response.use(
  (response) => response,
  async (error) => {
    const originalRequest = error.config;
    if (
      error.config.url !== "/api-nonfood/login" &&
      error.response.status === 401 &&
      !originalRequest._retry
    ) {
      originalRequest._retry = true;
      const tokens = await api
        .post<string>("/api-nonfood/refresh", {}, { withCredentials: true })
        .then((res) => res.data);
      localStorage.setItem("token", tokens);
      return api(originalRequest);
    }
    return Promise.reject(error);
  }
);

export default api;
