import React, { FC } from "react";
import { ErrorMessage } from "@hookform/error-message";
import { FieldErrors } from "react-hook-form";
import styled from "@emotion/styled";
import { CheckIcon } from "@heroicons/react/24/solid";

const RequiredSpan = styled("span")(() => ({
  color: "#de350b",
  paddingLeft: 2,
  fontSize: "0.857143em",
  lineHeight: "1.33333",
  fontStyle: "inherit",
  fontWeight: 600,
}));

type CheckboxProps = {
  label?: string;
  errors?: FieldErrors<any>;
  onChange: (value: boolean) => void;
  name?: string;
  checked: boolean;
  required?: boolean;
  disabled?: boolean;
};

const CheckboxLabel = styled("label", {
  shouldForwardProp: (prop) => prop !== "disabled",
})<{ disabled?: boolean }>(({ disabled }) => ({
  webkitBoxAlign: "center",
  display: "flex",
  color: "#091e42",
  height: "100%",
  flexDirection: "row",
  alignItems: "center",
  opacity: 1,
  cursor: "default",
  ...(disabled && {
    opacity: 0.5,
    cursor: "not-allowed",
  }),
}));

const CheckboxSpan = styled("span")(() => ({
  fontSize: "0.857143em",
  fontStyle: "inherit",
  height: 20,
  color: "#6B778C",
  fontWeight: 600,
  display: "inline-block",
  marginTop: 0,
  marginBottom: 4,
  paddingRight: 4,
  paddingLeft: 4,
  margin: 0,
  userSelect: "none",
}));

const Checkbox: FC<CheckboxProps> = (props) => {
  const handleClick = () => {
    if (props.disabled) return;
    props.onChange(!props.checked);
  };

  return (
    <>
      <CheckboxLabel
        data-testid={"checkbox-label"}
        disabled={props.disabled}
        onClick={handleClick}
      >
        <div
          data-testid={"checkbox-checked-wrapper"}
          className={
            "w-4 h-4 border-1 border-solid border-gray-300 rounded-sm flex flex-row justify-center items-center" +
            (props.checked ? " bg-orange-400 border-orange-400" : "")
          }
        >
          {props.checked && (
            <CheckIcon
              data-testid={"checkbox-checked-icon"}
              className={"w-3 h-3 text-white"}
            />
          )}
        </div>
        {typeof props.label !== "undefined" && props.label.length > 0 && (
          <CheckboxSpan>
            {props.label}
            {props.required && <RequiredSpan>*</RequiredSpan>}
          </CheckboxSpan>
        )}
      </CheckboxLabel>
      {props.errors && props.name && (
        <ErrorMessage
          name={props.name}
          errors={props.errors}
          render={({ message }) => <p className={"error-message"}>{message}</p>}
        />
      )}
    </>
  );
};

export default Checkbox;
