import { ChangeEvent, FC, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import defaultImage from "../assets/default.png";
import { Artikel, artikelDescription, useApp } from "../useAppProvider";
import { formatEuro } from "../currency";
import api from "../api";
import { MinusIcon, PlusIcon, TrashIcon } from "@heroicons/react/24/outline";

interface OrderviewItemProps {
  artikel: Artikel;
}

const OverviewItem: FC<OrderviewItemProps> = (props) => {
  const { t } = useTranslation();
  const app = useApp();
  const [img, setImg] = useState<string | null>(null);
  const [loading, setLoading] = useState<boolean>(false);

  useEffect(() => {
    let cancel = false;

    api
      .get<string | void>("/api-nonfood/artikel/" + props.artikel.No + "/image")
      .then((res) => {
        if (!cancel) {
          if (res.data) {
            setImg(res.data);
          } else {
            setImg(defaultImage);
          }
        }
      })
      .catch(() => {
        if (!cancel) {
          setImg(defaultImage);
        }
      })
      .finally(() => {
        if (!cancel) {
          setLoading(false);
        }
      });

    return () => {
      cancel = true;
    };
  }, [props.artikel.No]);

  const price = useMemo(() => {
    if (
      typeof props.artikel.quantity !== "undefined" &&
      props.artikel.quantity !== null
    ) {
      return formatEuro(
        props.artikel.quantity * props.artikel.Last_Direct_Cost
      );
    }
    return formatEuro(props.artikel.Last_Direct_Cost);
  }, [props.artikel]);

  const handleQuantityChange = (event: ChangeEvent<HTMLInputElement>) => {
    try {
      const value = parseInt(event.target.value);
      if (props.artikel !== null && value >= 1) {
        const copy = { ...props.artikel, quantity: value };
        app.cartArtikelChange(copy);
      }
    } catch {
      // ignore
    }
  };

  const handleAddQuantity = () => {
    const copy = {
      ...props.artikel,
      quantity: props.artikel.quantity ? props.artikel.quantity + 1 : 1,
    };
    app.cartArtikelChange(copy);
  };

  const handleSubtractQuantity = () => {
    if (props.artikel) {
      if (props.artikel.quantity && props.artikel.quantity > 1) {
        const copy = {
          ...props.artikel,
          quantity: props.artikel.quantity ? props.artikel.quantity - 1 : 1,
        };
        app.cartArtikelChange(copy);
      } else {
        const copy = { ...props.artikel, quantity: 1 };
        app.cartArtikelChange(copy);
      }
    }
  };

  return (
    <div className={"flex flex-row w-full flex-nowrap gap-4"}>
      <div
        className={
          "bg-gray-400 w-32 h-32 bg-no-repeat bg-center bg-contain" +
          (loading ? " animate-pulse" : "")
        }
        style={{ backgroundImage: "url(" + img + ")" }}
      />
      <div className={"flex flex-col flex-nowrap w-full"}>
        <div className={"w-full flex flex-nowrap flex-row justify-between"}>
          <div>
            <p className={"text-xs text-gray-400"}>{props.artikel.Vendor_No}</p>
            <h2 className={"text-sm font-bold"}>
              {artikelDescription(props.artikel)}
            </h2>
          </div>
          <div className={"flex flex-col flex-nowrap justify-start"}>
            <button
              className={
                "flex flex-row justify-center items-center hover:bg-gray-200 rounded p-1"
              }
              type={"button"}
              onClick={() => app.removeCartItem(props.artikel)}
            >
              <TrashIcon className={"w-5 h-5"} />
            </button>
          </div>
        </div>
        {props.artikel.unit && (
          <div className={"text-xs flex flex-col gap-1"}>
            <span>
              {t("length")}: {props.artikel.unit && props.artikel.unit.Length}mm
            </span>
            <span>
              {t("width")}: {props.artikel.unit && props.artikel.unit.Width}mm
            </span>
            <span>
              {t("height")}: {props.artikel.unit && props.artikel.unit.Height}mm
            </span>
            <span>
              {t("width")}: {props.artikel.unit && props.artikel.unit.Weight}kg
            </span>
          </div>
        )}
        <div
          className={
            "mt-auto flex flex-row flex-nowrap justify-between items-center"
          }
        >
          <p className={"text-xs"}>
            {props.artikel.quantity} Stück à{" "}
            {formatEuro(props.artikel.Last_Direct_Cost || 0)}, gesamt{" "}
            <b>{price}</b>
          </p>
          <div className={"flex flex-row flex-nowrap gap-2"}>
            <button
              type={"button"}
              className={
                "flex flex-row justify-center items-center hover:bg-gray-200 rounded p-1"
              }
              onClick={handleSubtractQuantity}
            >
              <MinusIcon className={"w-4 h-4"} />
            </button>
            <input
              type={"number"}
              className={"min-w-32 w-32 text-center text-xs rounded"}
              autoCapitalize={"none"}
              autoComplete={"off"}
              autoCorrect={"off"}
              spellCheck={false}
              value={props.artikel.quantity}
              onChange={handleQuantityChange}
              min={1}
            />
            <button
              className={
                "flex flex-row justify-center items-center hover:bg-gray-200 rounded p-1"
              }
              type={"button"}
              onClick={handleAddQuantity}
            >
              <PlusIcon className={"w-4 h-4"} />
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

const Overview: FC = () => {
  const { t } = useTranslation();
  const app = useApp();

  return (
    <div className={"container mx-auto py-8 min-h-screen"}>
      <h1 className={"text-2xl font-bold mb-4"}>{t("orderOverview")}</h1>
      <div className={"flex flex-col gap-4 w-full"}>
        {app.cart.map((item, index) => (
          <OverviewItem artikel={item} key={index} />
        ))}
      </div>
      <div className={"flex w-full mt-8"}>
        <button
          onClick={() => app.submitOrder()}
          type={"button"}
          className={"ml-auto rounded-md bg-green-600 text-white py-2 px-6"}
        >
          Absenden
        </button>
      </div>
    </div>
  );
};

export default Overview;
