import React, { lazy, Suspense, useEffect } from "react";
import { Route, Routes } from "react-router-dom";
import Home from "./pages/home";
import Logout from "./pages/logout";
import Search from "./pages/search";
import Login from "./pages/login";
import { AnimatePresence, motion } from "framer-motion";
import Account from "./pages/account/account";
import Loading from "./components/loading";
import Overview from "./pages/overview";
import Backdrop from "./components/backdrop";
import Orders from "./pages/account/orders";
import Settings from "./pages/account/settings";
import Users from "./pages/account/users";
import LoadingPage from "./components/loadingpage";
import Header from "./components/header";
import Footer from "./components/footer";
import Cart from "./components/cart";
import CartMessage from "./components/cartMessage";
import LogoutQuestion from "./components/logoutQuestion";
import { useApp } from "./useAppProvider";
import User from "./pages/account/user";

const Artikel = lazy(() => import("./pages/artikel"));

function App() {
  const app = useApp();

  useEffect(() => {
    app.validate();
  }, []);

  if (app.validating) return <LoadingPage />;
  return (
    <Suspense fallback={<Loading />}>
      <Header />
      <main>
        <Routes>
          <Route path={""} element={<Home />} />
          <Route path={":no"} element={<Artikel />} />
          <Route path="logout" element={<Logout />} />
          <Route path="search/:value" element={<Search />} />
          <Route path={"overview"} element={<Overview />} />
          <Route path={"account"}>
            <Route index element={<Account />} />
            <Route path={"orders"} element={<Orders />} />
            <Route path={"settings"} element={<Settings />} />
            <Route path={"users"}>
              <Route index element={<Users />} />
              <Route path={":uuid"} element={<User />} />
            </Route>
          </Route>
          <Route path={"login"} element={<Login />} />
        </Routes>
      </main>
      <Footer />
      <AnimatePresence>{app.cartModal && <Cart />}</AnimatePresence>
      <AnimatePresence></AnimatePresence>
      <AnimatePresence>
        {app.warenkorbMessage && <CartMessage />}
      </AnimatePresence>
      <AnimatePresence>{app.logoutModal && <LogoutQuestion />}</AnimatePresence>
      <AnimatePresence>
        {app.submitOrderSuccess && (
          <Backdrop onClick={() => app.setSubmitOrderSuccess(false)}>
            <motion.div
              initial={{ scale: 0.9 }}
              animate={{ scale: 1 }}
              exit={{ scale: 0.9 }}
              className={"bg-white py-4 px-6 rounded-md shadow-md"}
              onClick={(e) => e.stopPropagation()}
            ></motion.div>
          </Backdrop>
        )}
      </AnimatePresence>
      <AnimatePresence>
        {app.logoutError && (
          <Backdrop onClick={() => app.setLogoutError(false)}>
            <motion.div
              className={"bg-white rounded absolute top-0 left-0 p-4"}
              initial={{ x: "-50%", y: "-10%" }}
              animate={{ x: "-50%", y: "-50%" }}
              exit={{ x: "-50%", y: "-10%" }}
              onClick={(e) => e.stopPropagation()}
            >
              <h1 className={"text-lg"}>Es ist etwas schief gelaufen ):</h1>
              <p className={"text-sm"}>Fehler beim Logout!</p>
              <div className={"flex flex-row justify-center items-center"}>
                <button
                  className={"rounded bg-gray-100 hover:bg-gray-200 px-4 py-2"}
                  type={"button"}
                >
                  OK
                </button>
              </div>
            </motion.div>
          </Backdrop>
        )}
      </AnimatePresence>
    </Suspense>
  );
}

export default App;
