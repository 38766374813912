import {
  ApiShopCategory,
  getShopCategoryItems,
  useApp,
} from "../useAppProvider";
import { FC, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { ChevronRightIcon } from "@heroicons/react/24/solid";

type FilterButtonProps = {
  selected: boolean;
  category: ApiShopCategory;
  level: number;
};

const FilterButton: FC<FilterButtonProps> = (props) => {
  const app = useApp();
  const { t } = useTranslation();

  const paddingLeft = useMemo(() => {
    if (props.level === 1) {
      return "pl-4";
    }
    if (props.level === 2) {
      return "pl-9";
    }
    if (props.level === 3) {
      return "pl-16";
    }
    return "";
  }, [props.level]);

  const font = useMemo(() => {
    if (props.level !== 1) {
      return "text-xs";
    }
    return "text-sm";
  }, [props.level]);

  return (
    <button
      className={`flex flex-row flex-nowrap w-full ${font} transition justify-between hover:bg-gray-200 cursor-pointer items-center pr-4 py-1 ${paddingLeft} ${
        props.selected ? "bg-gray-100" : ""
      }`}
      type={"button"}
      onClick={() => app.toggleCategory(props.category)}
    >
      <span className={"truncate"}>
        {t("categories." + props.category.Code + ".title")} (
        {getShopCategoryItems(props.category).length})
      </span>
      {props.category.ChildCategories.length > 0 && (
        <ChevronRightIcon
          className={
            "w-5 h-5 min-w-5 shrink-0 text-blue-500 transition will-change-transform" +
            (props.selected ? " transform rotate-90" : "")
          }
        />
      )}
    </button>
  );
};

export default FilterButton;
