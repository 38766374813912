import {
  ChangeEvent,
  KeyboardEvent,
  FocusEvent,
  MouseEvent,
  CSSProperties,
  forwardRef,
  useId,
} from "react";
import styled from "@emotion/styled";
import { FieldErrors } from "react-hook-form";
import { ErrorMessage } from "@hookform/error-message";
import Tooltip from "./tooltip";
import { motion } from "framer-motion";

export const SearchItem = styled("li", {
  shouldForwardProp: (prop) => prop !== "isSmall",
})<{ isSmall?: boolean }>(({ isSmall }) => ({
  padding: "12px 8px",
  fontSize: 14,
  "&:hover": {
    backgroundColor: "rgba(0,0,0,.04)",
    cursor: "pointer",
  },
  "&.selected": {
    backgroundColor: "rgba(0,0,0,.04)",
  },
  ...(isSmall && {
    padding: "6px 8px",
    fontSize: 11,
  }),
}));

interface IInputStyled {
  isSmall?: boolean;
}

const InputStyled = styled(motion.input, {
  shouldForwardProp: (prop) => prop !== "isSmall" && prop !== "maxWidth",
})<IInputStyled>(({ isSmall }) => ({
  outline: 0,
  borderBottomRightRadius: "3.01px",
  boxShadow: "none",
  padding: "8px 6px",
  height: "2.57rem",
  backgroundColor: "transparent",
  border: 0,
  boxSizing: "border-box",
  color: "inherit",
  cusror: "inherit",
  fontSize: 14,
  minWidth: 0,
  width: "100%",
  lineHeight: "1.42857",
  fontFamily: "inherit",
  "&:disabled": {
    cursor: "not-allowed",
    backgroundColor: "rgba(0,0,0,.1)",
  },
  ...(isSmall && {
    padding: "4px 6px",
    height: "2em",
  }),
}));

export const Wrapper = styled("div")(() => ({
  width: "100%",
  position: "relative",
}));

export const Select = styled("select")(() => ({
  outline: 0,
  borderBottomRightRadius: "3.01px",
  boxShadow: "none",
  padding: "8px 6px",
  height: "2.57rem",
  backgroundColor: "transparent",
  border: 0,
  boxSizing: "border-box",
  color: "inherit",
  cusror: "inherit",
  fontSize: 14,
  minWidth: 0,
  width: "100%",
  lineHeight: "1.42857",
}));

export const Label = styled("label")(() => ({
  fontSize: "0.857143em",
  fontStyle: "inherit",
  height: 20,
  color: "#6B778C",
  fontWeight: 600,
  display: "inline-block",
  marginTop: 0,
  marginBottom: 4,
}));

export const InputWrapper = styled("div")(() => ({
  WebkitBoxAlign: "center",
  alignItems: "center",
  backgroundColor: "#FAFBFC",
  borderColor: "#DFE1E6",
  color: "#091E42",
  cursor: "text",
  borderRadius: 3,
  borderWidth: 2,
  borderStyle: "solid",
  boxSizing: "border-box",
  display: "flex",
  flexWrap: "wrap",
  flex: "1 1 100%",
  fontSize: 14,
  width: "100%",
  WebkitBoxPack: "justify",
  justifyContent: "space-between",
  overflow: "hidden",
  transition:
    "background-color 0.2s ease-in-out 0s, border-color 0.2s ease-in-out 0s",
  overflowWrap: "break-word",
  verticalAlign: "top",
  pointerEvents: "auto",
  "&:hover": {
    backgroundColor: "#EBECF0",
  },
  "&:focus-within": {
    borderColor: "#f6bf86",
    backgroundColor: "#FFFFFF",
  },
}));

export const RequiredSpan = styled("span")(() => ({
  color: "#de350b",
  paddingLeft: 2,
  fontSize: "0.857143em",
  lineHeight: "1.33333",
  fontStyle: "inherit",
  fontWeight: 600,
}));

interface InputProps {
  label?: string;
  errors?: FieldErrors<any>;
  maxWidth?: number | string;
  info?: string;
  isSmall?: boolean;
  style?: CSSProperties;
  required?: boolean;
  name?: string;
  type?:
    | "text"
    | "password"
    | "email"
    | "number"
    | "search"
    | "tel"
    | "url"
    | "date"
    | "time"
    | "datetime-local";
  placeholder?: string;
  onChange?: (e: ChangeEvent<HTMLInputElement>) => void;
  onClick?: (e: MouseEvent<HTMLInputElement>) => void;
  value?: string | number | readonly string[];
  step?: number | "any";
  min?: number | string;
  max?: number | string;
  defaultValue?: string | number | readonly string[];
  disabled?: boolean;
  maxLength?: number;
  autoComplete?: string;
  autoFocus?: boolean;
  readOnly?: boolean;
  onKeyDown?: (e: KeyboardEvent<HTMLInputElement>) => void;
  id?: string;
  autoCapitalize?: string;
  autoCorrect?: string;
  spellCheck?: string;
  tabIndex?: number;
  onFocus?: (e: FocusEvent<HTMLInputElement>) => void;
}

const Input = forwardRef<HTMLInputElement, InputProps>((props, ref) => {
  const htmlId = useId();
  const { required, label, info, errors, maxWidth, isSmall, ...rest } = props;
  return (
    <Wrapper style={{ maxWidth }}>
      {(typeof label !== "undefined" || typeof info !== "undefined") && (
        <div className={"w-full flex flex-row items-baseline h-[24px]"}>
          {typeof label !== "undefined" && (
            <Label htmlFor={htmlId}>
              {label}
              {required && <RequiredSpan>*</RequiredSpan>}
            </Label>
          )}
          <div className={"grow"} />
          {typeof info !== "undefined" && (
            <Tooltip text={info}>
              <span style={{ fontSize: 13, cursor: "pointer" }}>?</span>
            </Tooltip>
          )}
        </div>
      )}
      <InputWrapper>
        <InputStyled
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          transition={{ duration: 0.2 }}
          {...rest}
          style={{ ...rest.style, maxWidth: "100%" }}
          isSmall={rest.type === "search" || isSmall}
          autoComplete={"off"}
          autoCapitalize={"none"}
          autoCorrect={"off"}
          spellCheck={"false"}
          ref={ref}
          id={htmlId}
        />
      </InputWrapper>
      {typeof errors !== "undefined" && rest.name && (
        <ErrorMessage
          name={rest.name}
          errors={errors}
          render={({ message }) => <p className={"error-message"}>{message}</p>}
        />
      )}
    </Wrapper>
  );
});

export default Input;
