import { FC, HTMLAttributes, useState } from "react";
import { AnimatePresence, motion } from "framer-motion";

interface TooltipProps extends HTMLAttributes<HTMLDivElement> {
  text: string;
}

const Tooltip: FC<TooltipProps> = ({ text, children, className, ...rest }) => {
  const [show, setShow] = useState<boolean>(false);

  return (
    <div>
      <div
        onMouseEnter={() => setShow(true)}
        onMouseLeave={() => setShow(false)}
        className={"relative" + (className ? " " + className : "")}
        {...rest}
      >
        {children}
        <AnimatePresence>
          {show && (
            <motion.div
              data-testid={"tooltip"}
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
              transition={{ duration: 0.3 }}
              style={{ borderRadius: 3 }}
              className={
                "fixed z-10 p-2 text-xs bg-zinc-600 text-white shadow-card max-w-xs mt-1"
              }
            >
              {text}
            </motion.div>
          )}
        </AnimatePresence>
      </div>
    </div>
  );
};

export default Tooltip;
