import { FC, ReactNode } from "react";
import { MagnifyingGlassIcon } from "@heroicons/react/24/solid";
import { useNavigate } from "react-router-dom";
import { ShieldCheckIcon, UserGroupIcon } from "@heroicons/react/24/outline";
import { useTranslation } from "react-i18next";

interface AccountItemProps {
  to: string;
  title: string;
  subtitle: string;
  children?: ReactNode;
}

const AccountItem: FC<AccountItemProps> = ({
  to,
  title,
  subtitle,
  children,
}) => {
  const navigate = useNavigate();

  return (
    <div
      onClick={() => navigate(to)}
      className={
        "col-span-1 flex flex-row flex-nowrap gap-4 rounded-md border-1 border-solid border-gray-300 cursor-pointer p-4"
      }
    >
      <div
        className={
          "w-20 min-w-[5rem] h-20 bg-blue-100 rounded-full flex flex-row justify-center items-center"
        }
      >
        {children}
      </div>
      <div className={"flex flex-col gap-1 justify-center"}>
        <h6>{title}</h6>
        <p className={"text-xs"}>{subtitle}</p>
      </div>
    </div>
  );
};

const Account: FC = () => {
  const { t } = useTranslation();
  return (
    <div className={"container max-w-screen-lg mx-auto py-8"}>
      <h4 className={"text-3xl font-bold mb-4"}>{t("myAccount")}</h4>
      <div className={"grid grid-cols-3 gap-4"}>
        <AccountItem
          to={"orders"}
          title={t("myOrders")}
          subtitle={
            "Sendungen nachverfolgen, Artikel zurückgeben oder erneut kaufen"
          }
        >
          <MagnifyingGlassIcon className={"w-10 h-10"} />
        </AccountItem>
        <AccountItem
          to={"settings"}
          title={t("myProfile")}
          subtitle={"Anmeldedaten, Name und Mobilfunknummer bearbeiten"}
        >
          <ShieldCheckIcon className={"w-10 h-10"} />
        </AccountItem>
        <AccountItem
          to={"users"}
          title={t("users.title")}
          subtitle={"Erstellen Sie Benutzer die Bestellungen tätigen können"}
        >
          <UserGroupIcon className={"w-10 h-10"} />
        </AccountItem>
      </div>
    </div>
  );
};

export default Account;
