import { FC } from "react";
import {useForm} from "react-hook-form";
import {Link, useNavigate} from "react-router-dom";
import { useApp } from "../useAppProvider";

interface LoginData {
  email: string;
  password: string;
}

const Login: FC = () => {
  const app = useApp();
  const navigate = useNavigate();
  const { register, handleSubmit, formState } = useForm<LoginData>();

  const submit = (data: LoginData) => {
    app.login(data.email, data.password).then((route) => navigate(route));
  }


  return (
    <div className={"container mx-auto"}>
      <div className={"max-w-[500px] w-full mx-auto my-24"}>
        <div>
          <form onSubmit={handleSubmit(submit)}>
            <div className={"flex flex-col gap-4"}>
              <label className={"flex flex-col"}>
                E-Mail
                <input className={"py-2 px-3 rounded-md"} {...register("email")} required type={"email"} />
              </label>
              <label className={"flex flex-col"}>
                Passwort
                <input className={"py-2 px-3 rounded-md"} {...register("password")} required type={"password"} />
              </label>
              <button className={"bg-orange-400 hover:bg-orange-500 text-white rounded-md py-2 px-3"} type={"submit"} disabled={formState.isSubmitting}>Login</button>
              <Link className={"text-blue-500"} to={"/forgot-password"}>Passwort vergessen?</Link>
            </div>

          </form>
        </div>
      </div>
    </div>
  )
}

export default Login;