import { FC } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { useApp } from "../useAppProvider";

const Logout: FC = () => {
  const auth = useApp();
  const { t } = useTranslation();

  return (
    <div>
      {!auth.loggedIn && (
        <div className={"container mx-auto py-40"}>
          <h2 className={"text-3xl text-center"}>{t("loggedOut")}</h2>
          <h5 className={"text-xl text-center mt-4"}>
            {t("loggedOutSubtitle")}
          </h5>
          <div className={"flex flex-row justify-center mt-32"}>
            <Link
              className={"py-4 px-6 bg-orange-400 text-white rounded-md"}
              to={"/"}
            >
              {t("navigateHome")}
            </Link>
          </div>
        </div>
      )}
    </div>
  );
};

export default Logout;
